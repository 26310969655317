<div class="app-add-vehicle-dialog">
  <div class="header">
    <h3>Add new vehicle</h3>
    <button mat-icon-button class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div class="content" [style.opacity]="!vehicleTypes ? '0.5' : '1'">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label>Year</mat-label>
        <mat-select formControlName="year" (selectionChange)="onYearChange()">
          <mat-option *ngFor="let option of data().years" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Make</mat-label>
        <mat-select formControlName="make" (selectionChange)="onMakeChange()">
          <mat-option *ngFor="let option of data().makes" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Model</mat-label>
        <mat-select formControlName="model" (selectionChange)="onModelChange()">
          <mat-option *ngFor="let option of data().models" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Trim</mat-label>
        <mat-select formControlName="trim">
          <mat-option *ngFor="let option of data().trims" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div style="display: flex; justify-content: flex-end;">
        <button [disabled]="isLoading() || formGroup.invalid" mat-flat-button matStepperNext>
          Create vehicle
        </button>
      </div>
    </form>
  </div>
</div>
