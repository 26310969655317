import dayjs from 'dayjs';

export const prettyAddress = (...addr: string[]) => {
  return addr.filter((item) => item?.trim()).join(', ');
};

export const formatTime = (time: string) => {
  if (time && time !== '') {
    const defaultDate = dayjs();

    const combinedDateTime = `${defaultDate.format('YYYY-MM-DD')}T${time}`;

    const parsedTime = dayjs(combinedDateTime);

    const formattedTime = parsedTime.format('h:mm A');

    return formattedTime;
  } else {
    return '';
  }
};

export const formatDate = (time = undefined) => {
  if (!time) return "";
  const defaultDate = dayjs();
  return `${defaultDate.format("YYYY-MM-DD")}T${time}`;
};

export const capitalizeFirstLetter = (string: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : string;
};

export const getCurrentDateTime = (stationTz?: string): Date => {
  return stationTz ? new Date(new Date().toLocaleString("en-US", { timeZone: stationTz })) : new Date();
};

export const formatStationTime = (time: string): Date => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  const now = new Date();
  now.setHours(hours, minutes, seconds || 0, 0);
  return now;
};
