import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-filters-bottom-sheet',
  standalone: true,
  imports: [CommonModule, MatListModule, MatButtonModule, MatIconModule],
  templateUrl: './filters-bottom-sheet.component.html',
  styleUrl: './filters-bottom-sheet.component.scss',
})
export class FiltersBottomSheetComponent {
  private dialogRef = inject(MatDialogRef<FiltersBottomSheetComponent>);
  filters = [
    {
      name: 'Comment type',
      id: 'comment_type',
      options: [
        { name: 'Successfully charged', value: 'Successfully charged' },
        { name: "Couldn't charge", value: "Couldn't charge" },
        { name: 'General comment', value: 'General comment' },
      ],
    },
    {
      name: 'Rating',
      id: 'rating',
      options: [
        { name: 'Excellent', value: '5' },
        { name: 'Very good', value: '4' },
        { name: 'Average', value: '3' },
        { name: 'Poor', value: '2' },
        { name: 'Bad', value: '1' },
      ],
    },
  ];

  constructor(private router: Router, private route: ActivatedRoute) {}

  onSelectionChange(event, id) {
    const selectedItems = event.source.selectedOptions.selected.map(
      (option) => option.value,
    );

    this.router.navigate([], {
      queryParams: {
        [id]: selectedItems.join(',') || undefined,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  isSelected(id: string, value: string) {
    return this.route.snapshot.queryParamMap.get(id)?.split(',')?.includes(value);
  }

  close() {
    this.dialogRef.close();
  }
}
