import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { StationAmenity } from '@app/services/station.interface';

@Component({
  selector: 'app-amenities-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './amenities-list.component.html',
  styleUrl: './amenities-list.component.scss',
})
export class AmenitiesListComponent {
  @Input() amenities: StationAmenity[] = [];
  @Input() selected = false;
  @Input() selectable = false;

  generateIcon(name: string) {
    return `assets/amenities/${name.replace(/\s+/g, '_') + (this.selected ? 'Active' : 'Inactive')}.svg`;
  }
}
