import { Injectable } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AutocompleteRequest, SearchResult, GeocodeRequest, GeocodeResult, Origin} from '@app/services/model/search';
import { Observable } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { SessionService } from '@app/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private readonly autocompleteUrl = `${environment.api.search}/v1/autocomplete`;
  private readonly geocodeUrl = `${environment.api.search}/v1/geocode`;

  constructor(private authService: AuthService, private http: HttpClient, private sessionService: SessionService) {}

  autocomplete(text: string, origin: Origin): Observable<SearchResult[]> {
    const request: AutocompleteRequest = { text, origin };
    const sessionId = this.getSessionId();

    return this.authService.getIdToken().pipe(
      take(1),
      switchMap(token => {
        if (token) {
          const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-Transaction-Id': sessionId
          });

          return this.http.post<SearchResult[]>(this.autocompleteUrl, request, { headers });
        } else {
          console.error('Token not available');
          throw new Error('Authentication token not available');
        }
      })
    );
  }

  geocode(placeId: string, type: string): Observable<GeocodeResult> {
    const request: GeocodeRequest = { placeId, type };
    const sessionId = this.sessionService.getSessionId();

    return this.authService.getIdToken().pipe(
      take(1),
      switchMap(token => {
        if (token) {
          const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-Transaction-Id': sessionId
          });

          return this.http.post<GeocodeResult>(this.geocodeUrl, request, { headers }).pipe(
            // Clear the session after a successful geocode request
            switchMap(result => {
              this.sessionService.clearSession();
              return [result];
            })
          );
        } else {
          console.error('Token not available');
          throw new Error('Authentication token not available');
        }
      })
    );
  }

  private getSessionId(): string {
    let sessionId = this.sessionService.getSessionId();
    if (!sessionId) {
      sessionId = this.sessionService.generateSessionId();
    }
    return sessionId;
  }
}
