import { Component, Input, AfterViewInit, ViewChild, ElementRef, inject } from '@angular/core';
import { MatIcon } from "@angular/material/icon";
import { Map } from "leaflet";
import { MatIconButton } from "@angular/material/button";
import { OverlayModule } from "@angular/cdk/overlay";
import { SearchControlService } from "@app/pages/map/controls/services/search-control.service";
import { SearchOverlayComponent } from "@app/pages/map/controls/search-overlay/search-overlay.component";
import { NgClass } from "@angular/common";
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { SearchService } from '@app/services/search.service';
import { SearchResult, Origin } from '@app/services/model/search';

@Component({
  selector: 'app-search-control',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    OverlayModule,
    SearchOverlayComponent,
    NgClass
  ],
  templateUrl: './search-control.component.html',
  styleUrls: ['./search-control.component.scss']
})
export class SearchControlComponent implements AfterViewInit {

  @Input() map: Map;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;

  searchControlService = inject(SearchControlService);
  searchService = inject(SearchService);
  overlayOpen = this.searchControlService.overlayOpen;

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 0);

    fromEvent(this.searchInput.nativeElement, 'input').pipe(
      map((event: any) => event.target.value),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => {
      this.search(value);
      this.overlayOpen.set(true);
    });

    this.searchControlService.setMap(this.map);

    fromEvent<KeyboardEvent>(this.searchInput.nativeElement, 'keydown').pipe(
      filter(event => event.key === 'Enter')
    ).subscribe(() => {
      this.triggerGeocode();
    });
  }

  search(searchTerm: string) {
    if (!searchTerm) return;

    const origin: Origin = {
      latitude: this.map?.getCenter().lat,
      longitude: this.map?.getCenter().lng
    };

    this.searchService.autocomplete(searchTerm, origin).subscribe(results => {
      this.searchControlService.searchResults.set(results); // Update the search results signal
      this.searchControlService.selectedSearchResult.set(null); // Clear previous selection
    });
  }

  triggerGeocode() {
    const searchResults = this.searchControlService.searchResults();
    if (searchResults.length > 0) {
      this.searchControlService.selectedSearchResult.set(searchResults[0]); // Update the shared signal
    }
  }

  onSearchItemClick(searchResult: SearchResult) {
    this.searchControlService.selectedSearchResult.set(searchResult); // Update the shared signal
  }
}
