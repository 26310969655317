import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportInterface } from './feedback.interface';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private http: HttpClient) {}

  report(data: ReportInterface) {
    return this.http.post(`${environment.api.feedback}/issue/report`, data);
  }
}
