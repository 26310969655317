import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { StationListItemComponent } from '@app/components/profile/station-list-item/station-list-item.component';
import { StationWithImages } from '@app/services/profile.interface';
import { ProfileService } from '@app/services/profile.service';
import { StationService } from '@app/services/station.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-images',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, StationListItemComponent],
  templateUrl: './images.component.html',
  styleUrl: './images.component.scss',
})
export class ImagesComponent implements OnInit {
  userId = null;
  data = signal<StationWithImages[]>(undefined);

  constructor(
    private stationService: StationService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe((params) => {
      this.userId = params.get('userId');

      this.loadData();
    });
  }

  loadData() {
    if (!this.userId) return;

    this.profileService
      .getStationImagesByUserId(this.userId)
      .subscribe(async (data) => {
        const stations = await firstValueFrom(
          this.stationService.getStationById(
            data.map((image) => image.stationId),
          ),
        );

        this.data.update((prev) => {
          return [
            ...(prev ?? []),
            ...data.map((image) => {
              return {
                ...image,
                station: stations?.data?.stationById?.find(
                  (station) => String(station.id) === String(image.stationId),
                ),
              };
            }),
          ];
        });
      });
  }
}
