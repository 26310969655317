<div class="app-connector-item">
  <div class="connector">
    <img [src]="connector.icon" />
    <p>{{ connector.connectorType.name }}</p>
  </div>
  <p class="network"><strong>Network:</strong> {{ charger.networks.name }}</p>
  <ul class="stats">
    <li *ngIf="connector.powerType">{{ connector.powerType }}</li>
    <li>{{ connector?.status.split("_").join(" ") }}</li>
    <li *ngIf="connector.chargeKwh">{{ connector.chargeKwh }} kW</li>
    <li *ngIf="connector.chargeKwh >= 24">Rapid</li>
  </ul>

  <div class="activity-list" *ngIf="activityList().waiting.length > 0">
    <div class="collapse-header" (click)="toggleWaitingList()">
      <h4>Waiting</h4>
      <button mat-icon-button>
        <mat-icon>
          {{ waitingListOpen() ? "expand_more" : "expand_less" }}
        </mat-icon>
      </button>
    </div>
    <div class="activities-list" [style.display]="waitingListOpen() ? 'block' : 'none'">
      <div
        class="activity-list-item"
        *ngFor="let item of activityList().waiting"
      >
        <div class="header">
          <div class="avatar">
            <mat-icon>person</mat-icon>
            <app-gc-image [src]="getAuthorPhoto?.(item.userId)" alt="" />
          </div>
          <div class="author">
            <span class="name">{{ item.user.displayName }}</span>
            <span class="vehicle">
              {{ getDefaultVehicle(item.user.userPreferences.vehicles)?.make }}
              {{ getDefaultVehicle(item.user.userPreferences.vehicles)?.model }}
            </span>
          </div>
        </div>

        <p class="note" *ngIf="item.notes">{{ item.notes }}</p>
      </div>
    </div>
  </div>

  <div class="activity-list" *ngIf="activityList().charging.length > 0">
    <div class="collapse-header" (click)="toggleChargingList()">
      <h4>Charging now</h4>
      <button mat-icon-button>
        <mat-icon>
          {{ chargingListOpen() ? "expand_more" : "expand_less" }}
        </mat-icon>
      </button>
    </div>
    <div class="activities-list" [style.display]="chargingListOpen() ? 'block' : 'none'">
      <div
        class="activity-list-item"
        *ngFor="let item of activityList().charging"
      >
        <div class="header">
          <div class="avatar">
            <mat-icon>person</mat-icon>
            <app-gc-image [src]="getAuthorPhoto?.(item.userId)" alt="" />
          </div>
          <div class="author">
            <span class="name">{{ item.user.displayName }}</span>
            <span class="vehicle">
              {{ getDefaultVehicle(item.user.userPreferences.vehicles)?.make }}
              {{ getDefaultVehicle(item.user.userPreferences.vehicles)?.model }}
            </span>
          </div>
        </div>

        <p class="note" *ngIf="item.notes">{{ item.notes }}</p>
      </div>
    </div>
  </div>
</div>
