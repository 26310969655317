import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-vehicle-dialog',
  standalone: true,
  imports: [MatButtonModule, MatCardModule],
  templateUrl: './delete-vehicle-dialog.component.html',
  styleUrl: './delete-vehicle-dialog.component.scss',
})
export class DeleteVehicleDialogComponent {
  constructor(public dialogRef: MatDialogRef<DeleteVehicleDialogComponent>) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
