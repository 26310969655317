<div class="app-report-dialog">
  <div class="header">
    <h3>Report</h3>
    <button mat-icon-button class="close-button" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <ngx-simplebar [options]="{ autoHide: true }" style="max-height: 560px;">
    <div class="content">
      <p class="description">
        Thank you for helping us weed out inappropriate things.
      </p>
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div>
          <mat-form-field appearance="outline" (click)="toggleList()">
            <mat-label>Issue</mat-label>
            <mat-select formControlName="issue" value="REPORT_TYPES[formGroup.controls.issue.value] ?? 'Issue'">
              <mat-option [value]="issue.key" *ngFor="let issue of REPORT_TYPES | keyvalue: keepOriginalOrder">{{issue.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>Add any additional details (optional)</mat-label>
          <textarea formControlName="details" matInput rows="7" placeholder="Type here..."></textarea>
        </mat-form-field>

        <div style="display: flex; justify-content: flex-end;">
          <button [disabled]="isLoading() || formGroup.invalid" mat-flat-button matStepperNext>
            Report
          </button>
        </div>
      </form>
    </div>
  </ngx-simplebar>
</div>
