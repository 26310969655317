import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private http: HttpClient) {}

  getImage(url: string) {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      switchMap((blob: Blob) => {
        if (!blob.size) {
          return of(null); // Return an empty string for invalid images
        }

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Observable<string>((observer) => {
          reader.onloadend = () => {
            observer.next(reader.result as string);
            observer.complete();
          };
          reader.onerror = () => {
            observer.error('Error reading file');
          };
        });
      }),
      catchError(() => {
        return of(null); // Return an empty string or a default image URL
      }),
    );
  }
}
