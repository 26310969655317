import { CommonModule } from '@angular/common';
import {
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { PublicMetadataInterface } from '@app/services/user.interface';
import { UserService } from '@app/services/user.service';
import { VehicleInterface, VehicleType } from '@app/services/vehicle.interface';
import { VehicleService } from '@app/services/vehicle.service';
import { User } from 'firebase/auth';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-add-vehicle-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
  templateUrl: './add-vehicle-dialog.component.html',
  styleUrl: './add-vehicle-dialog.component.scss',
})
export class AddVehicleDialogComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<AddVehicleDialogComponent>);
  user: User = null;
  vehicleTypes: VehicleType[] = undefined;
  metadata: PublicMetadataInterface = null;
  private _formBuilder = inject(FormBuilder);
  formGroup = this._formBuilder.group({
    year: ['', Validators.required],
    make: [{ value: '', disabled: true }, Validators.required],
    model: [{ value: '', disabled: true }, Validators.required],
    trim: [{ value: '', disabled: true }],
  });
  isLoading = signal(false);
  data = signal<{
    years: string[];
    makes: string[];
    models: string[];
    trims: string[];
  }>({ years: [], makes: [], models: [], trims: [] });

  constructor(
    private vehicleService: VehicleService,
    private userService: UserService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.vehicleService.getVehicleTypes().subscribe((data) => {
      this.vehicleTypes = data;
      this.data.update((prev) => ({
        ...prev,
        years: this.getYears(this.vehicleTypes),
      }));
    });

    this.authService.user$.subscribe((user) => {
      this.user = user;

      this.userService.getMetadataById(user.uid).subscribe((data) => {
        this.metadata = data;
      });
    });
  }

  getYears(data: VehicleType[]): string[] {
    return Array.from(new Set(data.map((vehicle) => vehicle.year))).sort(
      (a, b) => b.localeCompare(a),
    );
  }

  getMakesByYear(data: VehicleType[], year: string): string[] {
    return Array.from(
      new Set(
        data
          .filter((vehicle) => vehicle.year === year)
          .map((vehicle) => vehicle.make),
      ),
    ).sort();
  }

  getModelsByMake(data: VehicleType[], year: string, make: string): string[] {
    return Array.from(
      new Set(
        data
          .filter((vehicle) => vehicle.year === year && vehicle.make === make)
          .map((vehicle) => vehicle.model),
      ),
    ).sort();
  }

  getTrimsByModel(
    data: VehicleType[],
    year: string,
    make: string,
    model: string,
  ): string[] {
    return Array.from(
      new Set(
        data
          .filter(
            (vehicle) =>
              vehicle.year === year &&
              vehicle.make === make &&
              vehicle.model === model &&
              !!vehicle?.vehicleTrim,
          )
          .map((vehicle) => vehicle.vehicleTrim),
      ),
    ).sort();
  }

  onYearChange(): void {
    const makes = this.getMakesByYear(
      this.vehicleTypes,
      this.formGroup.value.year,
    );
    this.formGroup.controls.make.setValue('');
    this.formGroup.controls.model.setValue('');
    this.formGroup.controls.trim.setValue('');
    this.formGroup.controls.model.disable();
    this.formGroup.controls.trim.disable();

    if (makes.length === 0) {
      this.formGroup.controls.make.disable();
    } else {
      this.formGroup.controls.make.enable();
    }

    this.data.update((prev) => ({
      ...prev,
      makes,
    }));
  }

  onMakeChange(): void {
    const models = this.getModelsByMake(
      this.vehicleTypes,
      this.formGroup.value.year,
      this.formGroup.value.make,
    );
    this.formGroup.controls.model.setValue('');
    this.formGroup.controls.trim.setValue('');
    this.formGroup.controls.trim.disable();

    if (models.length === 0) {
      this.formGroup.controls.model.disable();
    } else {
      this.formGroup.controls.model.enable();
    }

    this.data.update((prev) => ({
      ...prev,
      models,
    }));
  }

  onModelChange(): void {
    const trims = this.getTrimsByModel(
      this.vehicleTypes,
      this.formGroup.value.year,
      this.formGroup.value.make,
      this.formGroup.value.model,
    );
    this.formGroup.controls.trim.setValue('');

    if (trims.length === 0) {
      this.formGroup.controls.trim.disable();
    } else {
      this.formGroup.controls.trim.enable();
    }

    this.data.update((prev) => ({
      ...prev,
      trims,
    }));
  }

  async onSubmit() {
    if (this.formGroup.invalid) return;

    const formData = this.formGroup.value;

    // Safely find the vehicle from the vehicle types
    const vehicle = this.vehicleTypes.find(
      (vehicle) =>
        vehicle.year === formData.year &&
        vehicle.make === formData.make &&
        vehicle.model === formData.model &&
        vehicle?.vehicleTrim === formData?.trim,
    ) as VehicleInterface;

    // If no matching vehicle is found, exit the function
    if (!vehicle) return;

    // Safely find if the vehicle already exists in the user's preferences
    const existingVehicle = this.metadata?.userPreferences?.vehicles?.find(
      (v) =>
        v.year === formData.year &&
        v.make === formData.make &&
        v.model === formData.model &&
        v?.vehicleTrim === formData?.trim,
    );

    // If the vehicle already exists, show a message and exit
    if (existingVehicle) {
      this.snackBar.open('Vehicle already exists', undefined, {
        duration: 3000,
      });
      return;
    }

    this.isLoading.set(true);

    // Safely handle updating metadata, ensuring null-safety for userPreferences and vehicles
    const updatedMetadata: PublicMetadataInterface = {
      ...this.metadata,
      userPreferences: {
        ...this.metadata?.userPreferences,
        vehicles: [
          ...(this.metadata?.userPreferences?.vehicles || []),
          {
            ...vehicle,
            // If no vehicles exist, set this one as the default
            default: !(this.metadata?.userPreferences?.vehicles?.length),
          },
        ],
      },
    };

    try {
      // Update the metadata
      await firstValueFrom(
        this.userService.updateMetadata(this.user.uid, updatedMetadata),
      );

      // Show success message
      this.snackBar.open('Vehicle added successfully', undefined, {
        duration: 3000,
      });
      this.isLoading.set(false);
      this.dialogRef.close();

      // TODO: Refetch the vehicles data
      // Workaround to reload the component
      this.router
        .navigateByUrl('/account', { skipLocationChange: true })
        .then(() => {
          this.router.navigate(['/account/vehicles']);
        });
    } catch (error) {
      // Handle the error and show failure message
      console.error('Error adding vehicle:', error);
      this.snackBar.open('Something went wrong', undefined, {
        duration: 3000,
      });
      this.isLoading.set(false);
    }
  }


  closeDialog(): void {
    this.dialogRef.close();
  }
}
