<header class="app-legal-header">
  <div class="container">
    <div class="logo">
      <a routerLink="/">
        <img src="logo-text.svg" alt="GitCharger logo" />
      </a>
    </div>

    <button mat-icon-button class="navbar-toggle" (click)="toggleMobileNav()">
      <mat-icon>menu</mat-icon>
    </button>

    <div class="links" [@toggleMobileNav]="mobileNavState">
      <nav class="navbar" (click)="toggleMobileNav()">
        <a
          routerLink="/about"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          About
        </a>
        <!-- <a
          routerLink="/"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Careers
        </a>
        <a
          routerLink="/"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Press
        </a>
        <a
          routerLink="/"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Brand
        </a>
        <a
          routerLink="/"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Advertising
        </a> -->
        <a
          routerLink="/contact"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Contact
        </a>
      </nav>

      <div class="right-buttons">
        <a routerLink="/" mat-flat-button>Open GitCharger</a>
      </div>
    </div>
  </div>
</header>

<router-outlet></router-outlet>

<div class="app-legal-footer">
  <div class="footer-logo">
    <img src="logo-footer.svg" alt="GitCharger logo" />

    <div class="links">
      <a routerLink="/privacy">Privacy Policy</a>
      <mat-divider [vertical]="true"></mat-divider>
      <a routerLink="/terms">Terms of Use</a>
    </div>
  </div>
  <div class="footer-download">
    <h2>Download Now</h2>
    <div class="download-links">
      <a
        href="https://play.google.com/store/apps/details?id=com.gitcharger.app"
        target="_blank"
      >
        <img src="assets/playstore.svg" />
      </a>
      <a
        href="https://apps.apple.com/us/app/gitcharger/id6450578675"
        target="_blank"
      >
        <img src="assets/appstore.svg" />
      </a>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="copyright">
      <p>{{ year }} GitCharger. All rights reserved.</p>
    </div>
    <div class="footer-links">
      <a target="_blank" href="https://twitter.com/GitCharger">
        <img src="assets/social/twitter.svg" alt="Twitter" />
      </a>
      <a target="_blank" href="https://www.instagram.com/gitcharger">
        <img src="assets/social/instagram.svg" alt="Instagram" />
      </a>
      <a target="_blank" href="https://www.reddit.com/r/gitcharger">
        <img src="assets/social/reddit.svg" alt="Reddit" />
      </a>
      <!-- <a target="_blank" href="https://discord.gg/x66rF2AGdm">
        <img src="assets/social/discord.svg" alt="Discord" />
      </a> -->
      <a target="_blank" href="https://www.youtube.com/@GitCharger">
        <img src="assets/social/youtube.svg" alt="Youtube" />
      </a>
      <a target="_blank" href="https://www.tiktok.com/@gitcharger">
        <img src="assets/social/tiktok.svg" alt="Tiktok" />
      </a>
    </div>
  </div>
</div>
