<div class="app-rewards app-stations-list">
  <div class="rewards-list">
    <div *ngFor="let reward of rewards()" class="reward-item {{ reward.inactive ? 'desaturated' : '' }}">
      <div class="icon">
        <img src="assets/rewards/{{ reward.rewardType }}.svg" alt="{{ reward.rewardType }}">
      </div>
      <p class="reward-name">{{ getRewardName(reward.rewardType) }}</p>
      <p class="date">{{ reward?.createdDate ? getRewardDate(reward.createdDate) : '' }}</p>
    </div>
  </div>

  <div class="stations-loading" *ngIf="rewards() === undefined">
    <mat-spinner [diameter]="20"></mat-spinner> Loading...
  </div>
</div>
