<div class="app-vehicles app-stations-list profile-page-content">
  <h1 class="account-page-title">My vehicles</h1>

  <div class="vehicles-list" *ngIf="data()?.length > 0">
    <div class="app-vehicle-item" *ngFor="let vehicle of data()">
      <div class="vehicle-radio">
        <mat-radio-button
          [checked]="vehicle.default"
          (click)="markAsDefault(vehicle)"
        ></mat-radio-button>
      </div>

      <div class="vehicle-logo">
        <mat-icon>directions_car</mat-icon>
        <app-gc-image
          [src]="getVehicleLogo(vehicle)"
          class="vehicle-image"
        ></app-gc-image>
      </div>

      <div class="vehicle-details">
        <p class="vehicle-make">{{ vehicle.make }}</p>
        <p class="vehicle-model">{{ vehicle.model }}</p>
        <p class="vehicle-trim" *ngIf="vehicle.vehicleTrim">
          {{ vehicle.vehicleTrim }}
        </p>
      </div>

      <div class="action-buttons">
        <button mat-icon-button (click)="deleteVehicle(vehicle)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="no-stations" *ngIf="data()?.length === 0">
    <img src="assets/link-expired.svg" />
    <p>No vehicles added yet.</p>
  </div>

  <div class="stations-loading" *ngIf="isLoading()">
    <mat-spinner [diameter]="20"></mat-spinner> Loading...
  </div>

  <button
    mat-stroked-button
    class="add-vehicle-button"
    (click)="openAddVehicleDialog()"
  >
    <mat-icon>add</mat-icon>
    Add vehicle
  </button>
</div>
