import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export const loadSvgIcons = (iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) => {
  iconRegistry.addSvgIcon(
    'google',
    sanitizer.bypassSecurityTrustResourceUrl('assets/icons/google.svg')
  );

  iconRegistry.addSvgIcon(
    'apple',
    sanitizer.bypassSecurityTrustResourceUrl('assets/icons/apple.svg')
  );

  iconRegistry.addSvgIcon(
    'gc_station',
    sanitizer.bypassSecurityTrustResourceUrl('assets/map/icons/station.svg')
  );
};
