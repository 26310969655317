import {bootstrapApplication, DomSanitizer} from '@angular/platform-browser';
import { appConfig } from '@app/app.config';
import { AppComponent } from '@app/app.component';
import {loadSvgIcons} from "@app/components/auth/social-login/icons";
import {MatIconRegistry} from "@angular/material/icon";

bootstrapApplication(AppComponent, appConfig)
  .then(ref => {
    const iconRegistry = ref.injector.get(MatIconRegistry);
    const sanitizer = ref.injector.get(DomSanitizer);
    loadSvgIcons(iconRegistry, sanitizer);
  })
  .catch((err) => console.error(err));
