<div class="grid-layout">
  <div class="auth-promo">
    <div class="promo-content">
      <p>Create your free profile to personalize your experience. Find the best EV stations, engage with the community, and much more!</p>
      <img src="assets/icons/signup.svg" alt="signup image"/>
    </div>
  </div>

  <div class="login-options">
    <form [formGroup]="registerForm" class="auth-form" (ngSubmit)="onRegister($event)">
      <h3 class="form-title">Register</h3>
      <div class="title-underline"></div>
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="displayName" />
        <mat-error *ngIf="registerForm.controls['displayName'].hasError('required')">Name is required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" />
        <mat-error *ngIf="registerForm.controls['email'].hasError('required')">Email is required</mat-error>
        <mat-error *ngIf="registerForm.controls['email'].hasError('email')">Please enter a valid email</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" />
        <mat-error *ngIf="registerForm.controls['password'].hasError('required')">Password is required</mat-error>
        <mat-error *ngIf="registerForm.controls['password'].hasError('minlength')">Password must be at least 6 characters</mat-error>
        <mat-error *ngIf="registerForm.controls['password'].hasError('customPassword')">Password must contain an uppercase letter, a special character</mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" class="create-account-button" [disabled]="isLoading()">
        <mat-spinner *ngIf="isLoading()" diameter="20"></mat-spinner>
        <span *ngIf="!isLoading()">Create Account</span>
      </button>
    </form>
    <app-social-login></app-social-login>

    <div style="margin-top: 16px">
      <button mat-button class="toggle-button" (click)="openSignInForm()">
        Already have an account? Log In
      </button>
    </div>
  </div>
</div>
