
import { StationPricingHours } from "@app/services/station.interface";
import { daysOfWeek } from "./consts";
import { formatStationTime, getCurrentDateTime } from "./index";

export interface IPricingHours {
  day: number;
  from?: string;
  to?: string;
  isPaid?: boolean;
}

type PricingHours = IPricingHours[];

// Convert the pricing hours object to IPricingHours[] format
export const convertStationPricingHoursToIPricingHours = (stationPricingHours: StationPricingHours): IPricingHours[] => {
  const iPricingHours: IPricingHours[] = [];

  daysOfWeek.forEach((day, index) => {
    const dayData = stationPricingHours?.pricingHours?.[day];

    if (dayData && dayData.length > 0) {
      dayData.forEach(hour => {
        iPricingHours.push({
          day: index,
          from: hour.from.length === 5 ? hour.from + ':00' : hour.from,
          to: hour.to.length === 5 ? hour.to + ':00' : hour.to,
          isPaid: stationPricingHours.isPaid,
        });
      });
    }
  });

  return iPricingHours;
};

// Determine the next change in pricing state
export const nextPricingChangeAt = (
  pricingHours: PricingHours,
  stationTz?: string,
): IPricingHours => {
  const now = getCurrentDateTime(stationTz);
  const currentDayIndex = now.getDay(); // Ensure index is 0-based
  const currentDayPricingHours = pricingHours.filter(
    (hours) => hours.day === currentDayIndex,
  );

  for (const hours of currentDayPricingHours) {
    const fromTime = hours.from ? formatStationTime(hours.from) : null;
    const toTime = hours.to ? formatStationTime(hours.to) : null;

    if (fromTime && toTime) {
      if (now >= fromTime && now >= toTime) {
        continue;
      }

      return { ...hours, day: currentDayIndex, from: fromTime.toTimeString().slice(0, 8), to: toTime.toTimeString().slice(0, 8) };
    }
  }

  // Check the next day's pricing shifts if current day's shifts are over
  let nextDayIndex = (currentDayIndex + 1) % 7;
  while (nextDayIndex !== currentDayIndex) {
    const nextDayPricingHours = pricingHours.filter(
      (hours) => hours.day === nextDayIndex,
    );

    if (nextDayPricingHours.length > 0) {
      const nextDayFromTime = nextDayPricingHours[0].from ? formatStationTime(nextDayPricingHours[0].from) : null;

      if (nextDayFromTime) {
        const nextDayFrom = new Date(now);
        nextDayFrom.setDate(nextDayFrom.getDate() + 1);
        nextDayFrom.setHours(nextDayFromTime.getHours(), nextDayFromTime.getMinutes(), nextDayFromTime.getSeconds());

        return { ...nextDayPricingHours[0], day: nextDayIndex, from: nextDayFrom.toTimeString().slice(0, 8) };
      }
    }
    nextDayIndex = (nextDayIndex + 1) % 7; // Move to the next day, wrapping around at 6
  }

  // Default case if no pricing times are set
  return { day: currentDayIndex };
};
