export const REWARD_TYPES = [
  'FIRST_FRIEND_ADDED',
  'FIRST_RATED_EXPERIENCE',
  'FIRST_STATION_ADDED',
  'FIRST_STATION_EDITED',
  'FIRST_STATION_SHARED',
  'FIRST_STATION_WITH_IMAGE',
  'FIRST_TRIP_PLAN',
  'FIRST_WISHLIST_ITEM_ADDED',
  'PROFILE_COMPLETION',
];
