import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { VehicleType } from './vehicle.interface';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  constructor(private http: HttpClient) { }

  getVehicleLogoUrl(make: string) {
    const makeUrl = make.replace(/\s+/g, '-').toLowerCase();
    return `${environment.api.file}/vehicle-logo/${makeUrl}.png`;
  }

  getVehicleTypes() {
    return this.http.get<VehicleType[]>(`${environment.api.station}/vehicle-type`);
  }
}
