import { Component, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-legal',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
  ],
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
  animations: [
    trigger('toggleMobileNav', [
      state(
        'hidden',
        style({
          opacity: 0,
          visibility: 'hidden',
          transform: 'translateY(10px)',
        })
      ),
      state(
        'visible',
        style({
          opacity: 1,
          visibility: 'visible',
          transform: 'translateY(0)',
        })
      ),
      transition('hidden <=> visible', [animate('150ms ease-in-out')]),
    ]),
  ],
})
export class LegalComponent implements OnDestroy {
  year = new Date().getFullYear();
  isMobile = false;
  mobileNavState = 'visible';
  resizeSubscription!: Subscription;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.checkScreenSize();
    this.resizeSubscription = fromEvent(window, 'resize').subscribe(() => {
      this.checkScreenSize();
    });
  }

  checkScreenSize() {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
      if (result.matches) {
        this.isMobile = true;
        this.mobileNavState = 'hidden';
      } else {
        this.isMobile = false;
        this.mobileNavState = 'visible';
      }
    });
  }

  toggleMobileNav() {
    if (this.isMobile) {
      this.mobileNavState = this.mobileNavState === 'hidden' ? 'visible' : 'hidden';
    }
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }
}
