import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private readonly sessionKey = 'searchSessionId';
  private readonly sessionExpiryKey = 'sessionExpiry';

  generateSessionId(): string {
    const sessionId = this.uuidv4();
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 1); // Expire in 1 day
    localStorage.setItem(this.sessionKey, sessionId);
    localStorage.setItem(this.sessionExpiryKey, expiryDate.toISOString());
    return sessionId;
  }

  getSessionId(): string | null {
    const sessionId = localStorage.getItem(this.sessionKey);
    const expiryDate = localStorage.getItem(this.sessionExpiryKey);

    if (sessionId && expiryDate) {
      const now = new Date();
      if (new Date(expiryDate) > now) {
        return sessionId;
      } else {
        this.clearSession();
      }
    }
    // generate a new session
    return this.generateSessionId();
  }

  clearSession(): void {
    localStorage.removeItem(this.sessionKey);
    localStorage.removeItem(this.sessionExpiryKey);
  }

  private uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
