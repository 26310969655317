import { Component, Input, OnChanges, OnInit, signal } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-gc-image',
  standalone: true,
  imports: [],
  templateUrl: './gc-image.component.html',
  styleUrl: './gc-image.component.scss',
})
export class GcImageComponent implements OnChanges {
  @Input() src = '';
  @Input() alt = '';
  @Input() style = '';
  @Input() noHeader = false;
  imageUrl = signal('');

  constructor(private authService: AuthService) {}

  async ngOnChanges() {
    this.imageUrl.set(this.src);

    if (!this.noHeader) {
      const token = await firstValueFrom(this.authService.getIdToken());
      if (!token) {
        return;
      }

      fetch(this.src, { headers: { Authorization: `Bearer ${token}` } })
        .then((r) => r.blob())
        .then((d) => this.imageUrl.set(window.URL.createObjectURL(d)));
    }
  }
}
