<div class="social-login">
  <div class="separator">
    <span>or</span>
  </div>
  <div class="social-login-buttons">
    <button mat-stroked-button class="google-login" color="primary" (click)="loginWithGoogle()">
      <mat-icon svgIcon="google"></mat-icon>
      <span>Continue with Google</span>
    </button>
    <button mat-stroked-button class="apple-login" color="primary" (click)="loginWithApple()">
      <mat-icon svgIcon="apple"></mat-icon>
      <span>Continue with Apple</span>
    </button>
  </div>
</div>
