import { CommonModule } from '@angular/common';
import { Component, Inject, inject, Input, signal } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioButton } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@app/services/auth.service';
import { CategoryType, IssueType } from '@app/services/feedback.interface';
import { FeedbackService } from '@app/services/feedback.service';
import { REPORT_TYPES } from '@app/shared/data/report';
import { catchError, finalize, of } from 'rxjs';
import { SimplebarAngularModule } from 'simplebar-angular';

@Component({
  selector: 'app-report-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatListModule,
    MatRadioButton,
    SimplebarAngularModule,
    MatSelectModule,
  ],
  templateUrl: './report-dialog.component.html',
  styleUrl: './report-dialog.component.scss',
})
export class ReportDialogComponent {
  dialogRef = inject(MatDialogRef<ReportDialogComponent>);
  private _formBuilder = inject(FormBuilder);
  formGroup = this._formBuilder.group({
    issue: ['', Validators.required],
    details: [''],
  });
  isLoading = signal(false);
  REPORT_TYPES = REPORT_TYPES;
  listExpanded = signal(false);

  keepOriginalOrder = () => 0;

  constructor(
    private snackBar: MatSnackBar,
    private feedbackService: FeedbackService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA)
    public props: { categoryType: CategoryType; categoryId: string },
  ) {
    this.authService.whenNotAuthenticated(() => {
      this.dialogRef.close();
    });
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (!this.formGroup.valid) return;

    const formData = this.formGroup.value;

    this.isLoading.set(true);

    this.feedbackService
      .report({
        userId: this.authService.userSignal()?.uid,
        categoryType: this.props.categoryType,
        categoryId: this.props.categoryId,
        issueType: formData.issue as IssueType,
        description: formData.details,
      })
      .pipe(
        catchError(() => {
          this.snackBar.open('Error submitting report', undefined, {
            duration: 3000,
          });
          throw new Error('Error submitting report');
        }),
        finalize(() => {
          this.isLoading.set(false);
        }),
      )
      .subscribe(() => {
        this.snackBar.open('Report submitted', undefined, { duration: 3000 });
        this.dialogRef.close();
      });
  }

  toggleList(): void {
    this.listExpanded.set(!this.listExpanded());
  }

  selectIssue(issue: { key: string; value: string }) {
    this.formGroup.get('issue')?.setValue(issue.key);
    this.toggleList();
  }
}
