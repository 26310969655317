import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import {
  Auth,
  GoogleAuthProvider,
  OAuthProvider,
  User,
  createUserWithEmailAndPassword,
  getIdToken,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  user,
} from '@angular/fire/auth';
import { environment } from '@environments/environment';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';

export interface UserSignal {
  uid: string;
  displayName: string;
  email: string;
  permission: 'read-only' | 'logged-in';
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private firebaseAuth = inject(Auth);
  private http = inject(HttpClient);
  user$: Observable<User> = user(this.firebaseAuth);
  userSignal = signal<UserSignal | null | undefined>(undefined);

  register(email: string, displayName: string, password: string): Observable<void> {
    const promise = createUserWithEmailAndPassword(
      this.firebaseAuth,
      email,
      password
    ).then(res => updateProfile(res.user, {
      displayName: displayName
    }));

    return from(promise);
  }

  login(email: string, password: string): Observable<void> {
    const promise = signInWithEmailAndPassword(this.firebaseAuth, email, password)
      .then(res => {
        return getIdToken(res.user).then(idToken => {
          this.storeTokens(res.user);
        });
      });

    return from(promise);
  }

  loginWithToken(token: string) {
    const promise = signInWithCustomToken(this.firebaseAuth, token)
      .then(res => {
        return getIdToken(res.user).then(idToken => {
          this.storeTokens(res.user);
        });
      });

    return from(promise);
  }

  loginWithGoogle(): Observable<void> {
    const provider = new GoogleAuthProvider();
    const promise = signInWithPopup(this.firebaseAuth, provider)
      .then(res => {
        return getIdToken(res.user).then(idToken => {
          this.storeTokens(res.user);
        });
      });

    return from(promise);
  }

  loginWithApple(): Observable<void> {
    const provider = new OAuthProvider('apple.com');
    const promise = signInWithPopup(this.firebaseAuth, provider)
      .then(res => {
        return getIdToken(res.user).then(idToken => {
          this.storeTokens(res.user);
        });
      });

    return from(promise);
  }

  logout() {
    return this.firebaseAuth.signOut();
  }

  verifyCode(email: string, code: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.api.user}/users/verify`, {
      email,
      code
    })
  }

  resendVerificationCode(email: string) {
    return this.http.post(`${environment.api.user}/users/send-verify-email`, {
      email
    });
  }

  ensureUserSignedIn() {
    return this.user$.pipe(switchMap(async (user) => {
      if (!user) {
        try {
          const userCredential = await signInAnonymously(this.firebaseAuth);
          user = userCredential.user;
        } catch (error) {
          console.error('Error during anonymous login:', error);
          return null;
        }
      }
      return user;
    }))
  }

  getIdToken(): Observable<string | null> {
    return this.ensureUserSignedIn().pipe(
      switchMap(user => user ? from(user.getIdToken()) : of(null)),
      catchError(error => {
        console.error('Error getting ID token:', error);
        return of(null);
      })
    );
  }

  isAuthenticated(permission?: UserSignal['permission']) {
    const authenticatedUser = permission || this.userSignal()?.permission;
    return authenticatedUser === 'logged-in';
  }

  getUserObject(): Observable<UserSignal | null> {
    return this.user$.pipe(
      map(user => {
        if (user) {
          return {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            permission: user.emailVerified ? 'logged-in' : 'read-only'
          };
        } else {
          return null;
        }
      })
    );
  }

  whenNotAuthenticated(callback: () => void) {
    this.getUserObject().subscribe(user => {
      if (!this.isAuthenticated(user?.permission)) {
        callback();
      }
    });
  }

  updatePassword(userId: string, password: string) {
    return this.http.put(`${environment.api.user}/users/password/${userId}`, {
      password,
    })
  }

  async storeTokens(user: User) {
    const idToken = await user.getIdToken();
    const refreshToken = user.refreshToken;
    document.cookie = `idToken=${idToken}; path=/; secure`;
    document.cookie = `refreshToken=${refreshToken}; path=/; secure`;
  }
}
