import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { SkeletonComponent } from '@app/components/skeleton/skeleton.component';
import { Station, StationImage } from '@app/services/station.interface';
import { StationService } from '@app/services/station.service';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-images-tab',
  standalone: true,
  imports: [
    InfiniteScrollDirective,
    CommonModule,
    NgOptimizedImage,
    SkeletonComponent,
    RouterLink,
    MatProgressSpinnerModule,
  ],
  templateUrl: './images-tab.component.html',
  styleUrl: './images-tab.component.scss',
})
export class ImagesTabComponent implements OnInit {
  @Input() station: Station = null;
  images: WritableSignal<StationImage[]> = signal([]);
  nextPageToken = undefined;
  hasMore = true;
  selector = '.simplebar-content-wrapper';
  isLoading = signal(false);

  constructor(private stationService: StationService) {}

  ngOnInit() {
    this.isLoading.set(true);
    this.loadData();
  }

  loadData() {
    this.stationService
      .getStationImagesById(this?.station?.id, this.nextPageToken)
      .subscribe((data) => {
        this.images.update((prev) => [...prev, ...data.content]);
        this.nextPageToken = data?.nextToken;
        this.hasMore = !!data?.nextToken;
        this.isLoading.set(false);
      });
  }

  onScroll() {
    if (this.hasMore) {
      this.loadData();
    }
  }

  getLink(image: StationImage) {
    return `image/${encodeURIComponent(btoa(JSON.stringify(image)))}`;
  }
}
