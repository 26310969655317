import { Injectable, signal } from '@angular/core';
import { GeocodeResult, SearchResult } from '@app/services/model/search';
import { Map, Marker, LatLng, Icon } from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class SearchControlService {

  overlayOpen = signal(false);
  recentSearches = signal<GeocodeResult[]>([]);
  searchResults = signal<SearchResult[]>([]); // Signal for search results
  selectedSearchResult = signal<SearchResult | null>(null); // Shared signal
  map: Map;
  private markers: Marker[] = [];

  constructor() {
    this.loadRecentSearches();
  }

  loadRecentSearches() {
    const storedSearches = localStorage.getItem('recentGeocodes');
    if (storedSearches) {
      this.recentSearches.set(JSON.parse(storedSearches));
    }
  }

  addToRecentSearch(geocodeResult: GeocodeResult) {
    const recentSearches = this.recentSearches();

    const updatedSearches = [geocodeResult, ...recentSearches].slice(0, 5); // Keep only the last 5 searches

    this.recentSearches.set(updatedSearches);

    localStorage.setItem('recentGeocodes', JSON.stringify(updatedSearches));

    this.addPinToMap(geocodeResult);
  }

  clearSelectedSearchResult() {
    this.selectedSearchResult.set(null); // Clear the selected search result
  }

  setMap(map: Map) {
    this.map = map;
  }

  addPinToMap(geocodeResult: GeocodeResult) {
    if (!this.map) {
      console.error("Map is not initialized");
      return;
    }

    const customPinIcon = new Icon({
      iconUrl: 'assets/map/icons/LocationMarker.svg',
      iconSize: [52, 52], // Adjust the size to match your SVG
      iconAnchor: [26, 52], // Anchor point in the icon (bottom center)
      popupAnchor: [0, -52], // Point from which the popup should open relative to the iconAnchor
    });

    const { latitude, longitude } = geocodeResult;
    const latLng = new LatLng(latitude, longitude);

    // Create a marker and add it to the map
    const marker = new Marker(latLng, {icon: customPinIcon}).addTo(this.map);

    // Create a popup with the address and bind it to the marker
    const popupContent = `<strong>${geocodeResult.address.formattedAddress}</strong>`;
    marker.bindPopup(popupContent).openPopup();
    this.markers.push(marker);

    // Optionally center the map on the new marker
    this.map.setView(latLng, 14); // Adjust the zoom level as needed
  }

  removeLocationMarkers() {
    this.markers = this.markers.filter(marker => {
      this.map.removeLayer(marker); // Remove marker from map if the name matches
      return false; // Remove marker from array
    });
  }
}
