import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Bookmark } from '@app/services/station.interface';
import { StationService } from '@app/services/station.service';
import { prettyAddress } from '@app/shared/utils';
import { GcImageComponent } from '../gc-image/gc-image.component';
import { AuthService } from '@app/services/auth.service';
import { RouterLink } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-bookmark-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    RouterLink,
    MatButtonModule,
    GcImageComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './bookmark-dialog.component.html',
  styleUrl: './bookmark-dialog.component.scss',
})
export class BookmarkDialogComponent implements OnInit {
  bookmarks = signal<Bookmark[]>(null);
  dialogRef = inject(MatDialogRef<BookmarkDialogComponent>);
  prettyAddress = (address: string[]) => prettyAddress(...address);
  getImageUrl = null;

  constructor(
    private snackBar: MatSnackBar,
    private stationService: StationService,
    private authService: AuthService,
  ) {
    this.authService.whenNotAuthenticated(() => {
      this.dialogRef.close();
    });

    this.getImageUrl = this.stationService.getStationImageUrl;
  }

  ngOnInit(): void {

    this.stationService
      .getBookmarks(this.authService.userSignal()?.uid)
      .subscribe((data) => {
        this.bookmarks.set(data.reverse());
      });
  }

  close() {
    this.dialogRef.close();
  }

  removeBookmark(event, stationId: string | number) {
    event.stopPropagation();
    event.preventDefault();

    this.bookmarks.update(prev => prev.filter((bookmark) => String(bookmark.stationId) !== String(stationId)));

    this.stationService.deleteBookmark(stationId).subscribe(() => {
      this.snackBar.open('Bookmark removed', undefined, {
        duration: 3000,
      });
    });
  }
}
