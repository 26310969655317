<div class="app-images-tab">
  <div
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [infiniteScrollContainer]="selector"
    [fromRoot]="true"
    (scrolled)="onScroll()"
  ></div>

  @if (isLoading()) {
    <div style="text-align: center">Loading the images...</div>
  } @else {
    <div style="text-align: center" class="no-results" *ngIf="images().length === 0">
      No image has been uploaded
    </div>
  }

  <div class="masonry-layout">
    <a
      [routerLink]="getLink(image)"
      class="masonry-item"
      *ngFor="let image of images()"
      [attr.data-station-image]="image.blobName"
    >
      <app-skeleton
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        "
      ></app-skeleton>
      <img [src]="image.imageUrl" />
    </a>
  </div>
</div>
