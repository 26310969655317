<div class="legal-container prose-lg">
  <div class="header">
    <p>Terms</p>
    <h1>Electricity and GitCharger can<br />transform people’s lives</h1>
  </div>

  <div class="content">
    <p>Last updated: <b>July 14, 2024</b></p>

    <p>
      Welcome to GitCharger Inc. (“GitCharger” “we”, “us”, or “our”) helps
      Electric Vehicle (EV) users locate chargers around them. By accessing or
      using our app/website, you agree to be bound by these Terms of Service.
      Please read them carefully.
    </p>

    <h2>1. Acceptance of Terms</h2>
    <p>
      By using our services, you agree to comply with and be bound by these
      Terms of Service and our Privacy Policy. If you do not agree to these
      terms, please do not use our services.
    </p>

    <h2>2. Use of Our Services</h2>
    <ul>
      <li>
        <b>Eligibility:</b> You must be at least 13 years old to use our
        services. If you are under 18, you must have your parent or guardian's
        permission to use our services.
      </li>
      <li>
        <b>Account Registration:</b> You may need to create an account to access
        certain features of our app/website. You agree to provide accurate and
        complete information during registration and to keep your account
        information up-to-date.
      </li>
      <li>
        <b>Prohibited Activities:</b> You agree not to use our services for any
        unlawful purpose or in any way that could harm our app/website, its
        users, or any third party. This includes, but is not limited to,
        hacking, spamming, and distributing malware.
      </li>
    </ul>

    <h2>3. Intellectual Property</h2>
    <ul>
      <li>
        <b>Ownership:</b> All content, features, and functionality on our
        app/website, including text, graphics, logos, and software, are the
        exclusive property of GitCharger or its licensors.
      </li>
      <li>
        <b>License:</b> We grant you a limited, non-exclusive, non-transferable
        license to access and use our services for personal, non-commercial use.
        You may not reproduce, distribute, modify, or create derivative works
        from our content without our express written permission.
      </li>
    </ul>

    <h2>4. Disclaimers and Limitation of Liability</h2>
    <ul>
      <li>
        <b>Disclaimers:</b> Our services are provided "as is" and "as available"
        without warranties of any kind, either express or implied. We do not
        guarantee that our services will be uninterrupted or error-free.
      </li>
      <li>
        <b>Limitation of Liability:</b> To the fullest extent permitted by law,
        GitCharger shall not be liable for any indirect, incidental, special,
        consequential, or punitive damages, or any loss of profits or revenues,
        whether incurred directly or indirectly, or any loss of data, use
        goodwill, or other intangible losses resulting from (a) your use of our
        services; (b) any unauthorized access to or use of our servers and/or
        any personal information stored therein; and (c) any interruption or
        cessation of transmission to or from our services.
      </li>
    </ul>

    <h2>5. Indemnification</h2>
    <p>
      You agree to indemnify and hold harmless GitCharger, its affiliates, and
      their respective officers, directors, employees, and agents from and
      against any claims, liabilities, damages, losses, and expenses, including
      reasonable attorneys' fees, arising out of or in any way connected with
      your access to or use of our services or your violation of these Terms of
      Service.
    </p>

    <h2>6. Governing Law</h2>
    <p>
      These Terms of Service and any disputes arising out of or related to them
      will be governed by the laws of the State of Delaware, without regard to
      its conflict of laws principles.
    </p>

    <h2>7. Changes to These Terms</h2>
    <p>
      We may update these Terms of Service from time to time. We will notify you
      of any changes by posting the new Terms of Service on our app/website.
      Your continued use of our services after the changes take effect signifies
      your acceptance of the new terms.
    </p>

    <h2>8. Contact Us</h2>
    <p>
      If you have any questions about these Terms of Service, please contact us
      at contact&#64;gitcharger.com.
    </p>
  </div>
</div>
