<div class="app-bookmarks-dialog">
  <div class="header">
    <h3>Bookmarks</h3>
    <button mat-icon-button class="close-button" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div class="content">
    <div class="stations-list" *ngIf="bookmarks()?.length > 0">
      @for (bookmark of bookmarks(); track bookmark) {
        <a
          routerLink="/station/{{ bookmark.stationId }}"
          (click)="close()"
          class="station-item"
        >
          <div class="image">
            <mat-icon>ev_station</mat-icon>
            <app-gc-image [src]="getImageUrl?.(bookmark.stationId)" alt="" />
          </div>
          <div class="details">
            <p class="name" *ngIf="bookmark.stationName">
              {{ bookmark.stationName }}
            </p>
            <p class="address">
              {{ prettyAddress(bookmark.address) }}
            </p>
          </div>
          <button mat-icon-button class="delete-button" (click)="removeBookmark($event, bookmark.stationId)">
            <mat-icon>delete</mat-icon>
          </button>
        </a>
        <mat-divider *ngIf="!$last"></mat-divider>
      }
    </div>

    <div class="no-bookmarks" *ngIf="bookmarks()?.length === 0">
      <i> No saved stations yet </i>
      <p>Never lose track of your favourite charging stations again.</p>
      <p>Bookmark them for later!</p>
    </div>
    <div class="no-bookmarks" *ngIf="bookmarks() === null">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  </div>
</div>
