<mat-toolbar class="app-topbar-layout">
  <button
    mat-icon-button
    onclick="document.querySelector('mat-sidenav').classList.toggle('opened')"
    class="show-mobile toggle-sidebar"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <span style="flex: 1 1 auto"></span>

  <app-user-dropdown></app-user-dropdown>
</mat-toolbar>

<router-outlet></router-outlet>
