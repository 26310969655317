<mat-toolbar>
  <span class="register-login-spacer"></span>
  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<mat-dialog-content>
  <div class="grid-layout dialog-content">
    <div class="login-options">
      <app-register *ngIf="type === 'register'"></app-register>
      <app-login *ngIf="type === 'login'"></app-login>
      <app-verify *ngIf="type === 'verify'"></app-verify>
    </div>
  </div>
</mat-dialog-content>
