import { Component, inject } from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import { AuthService } from '@app/services/auth.service';
import { RegisterLoginComponent } from '../register-login/register-login.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-social-login',
  standalone: true,
  imports: [MatIcon, MatButtonModule],
  templateUrl: './social-login.component.html',
  styleUrl: './social-login.component.scss'
})
export class SocialLoginComponent {
  dialogRef = inject(MatDialogRef<RegisterLoginComponent>);

  constructor(private authService: AuthService, private snackBar: MatSnackBar) {
  }

  loginWithGoogle() {
    this.authService.loginWithGoogle().subscribe(() => {
      this.snackBar.open('User is logged in', undefined, { duration: 3000 });
      this.dialogRef.close();
    });
  }

  loginWithApple() {
    this.authService.loginWithApple().subscribe(() => {
      this.snackBar.open('User is logged in', undefined, { duration: 3000 });
      this.dialogRef.close();
    });
  }
}
