<div class="legal-container prose-lg">
  <div class="header">
    <p>Privacy</p>
    <h1>Electricity and GitCharger can<br />transform people’s lives</h1>
  </div>

  <div class="content">
    <p>Last updated: <b>July 14, 2024</b></p>

    <p>
      GitCharger Inc. (“GitCharger” “we”, “us”, or “our”) helps Electric Vehicle
      (EV) users locate chargers around them. Keep in mind your privacy is very
      important to us. This Privacy Policy explains how we collect, use,
      disclose, and protect your information when you use our app/website. By
      using our services, you agree to the terms of this Privacy Policy.
    </p>

    <h2>1. Information We Collect</h2>
    <ul>
      <li>
        <b>Personal Information:</b> We may collect personal information such as
        a name, username, and email when you register for an account or contact
        us for support.<br />
      </li>
      <li>
        <b>Location Data:</b> With your consent, we collect and process location
        data to provide map-based services, including trip planning and charging
        station locations.<br />
      </li>
      <li>
        <b>Usage Data:</b> We collect information about you anonymously on how
        you use our app/website, including your interactions with the interface,
        the features you use, and the time spent on the app/website.<br />
      </li>
      <li>
        <b>Device Information:</b> We may collect information about your device,
        including the type of device, operating system, and unique device
        identifiers.
      </li>
    </ul>

    <h2>2. How We Use Your Information</h2>
    <ul>
      <li>
        <b>To Provide Services:</b> We use your information to operate,
        maintain, and improve our app/website, including providing trip planning
        and charging station location services.
      </li>
      <li>
        <b>To Communicate:</b> We may use your contact information to send you
        updates, notifications, and support messages.
      </li>
      <li>
        <b>To Improve User Experience:</b> We use usage data to understand how
        our app/website is used and to enhance its functionality and user
        experience.
      </li>
      <li>
        <b>For Security:</b> We use your information to detect and prevent
        fraud, abuse, and other harmful activities.
      </li>
    </ul>

    <h2>3. Sharing Your Information</h2>
    <ul>
      <li>
        <b>Legal Requirements:</b> We may disclose your information if required
        to do so by law or in response to valid requests by public authorities.
      </li>
      <li>
        <b>Business Transfers:</b> In the event of a merger, acquisition, or
        sale of assets, your information may be transferred to the new owner.
      </li>
    </ul>

    <h2>4. Your Choices</h2>
    <ul>
      <li>
        <b>Account Information:</b> You can update, correct, or delete your
        account information at any time by logging into your account.
      </li>
      <li>
        <b>Location Data:</b> You can enable or disable location services
        through your device settings.
      </li>
      <li>
        <b>Communication Preferences:</b> You can opt-out of receiving
        promotional communications from us by following the unsubscribe
        instructions in those messages.
      </li>
    </ul>

    <h2>5. Security</h2>
    <p>
      We implement appropriate technical and organizational measures to protect
      your information from unauthorized access, use, or disclosure.
    </p>

    <h2>6. Children's Privacy</h2>
    <p>
      Our app/website is not intended for children under the age of 13. We do
      not knowingly collect personal information from children under 13. If we
      learn that we have collected such information, we will delete it.
    </p>

    <h2>7. Changes to This Privacy Policy</h2>
    <p>
      We may update this Privacy Policy from time to time. We will notify you of
      any changes by posting the new Privacy Policy on our app/website. Your
      continued use of our services after the changes take effect signifies your
      acceptance of the new policy.
    </p>

    <h2>8. Contact Us</h2>
    <p>
      If you have any questions about this Privacy Policy, please contact us at
      contact&#64;gitcharger.com.
    </p>
  </div>
</div>
