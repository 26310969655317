<div class="app-filters-bottom-sheet">
  <button mat-icon-button class="close-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="filter-item" *ngFor="let filter of filters">
    <h3>{{ filter.name }}</h3>

    <mat-selection-list
      (selectionChange)="onSelectionChange($event, filter.id)"
    >
      <mat-list-option
        *ngFor="let option of filter.options"
        [value]="option.value"
        [selected]="isSelected(filter.id, option.value)"
      >
        {{ option.name }}
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>
