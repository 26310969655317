import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { StationListItemComponent } from '@app/components/profile/station-list-item/station-list-item.component';
import { ReviewItemComponent } from '@app/components/station-details/review-item/review-item.component';
import { ProfileService } from '@app/services/profile.service';
import { StationReview } from '@app/services/station.interface';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Component({
  selector: 'app-ratings',
  standalone: true,
  imports: [
    CommonModule,
    InfiniteScrollDirective,
    MatProgressSpinnerModule,
    StationListItemComponent,
    ReviewItemComponent,
  ],
  templateUrl: './ratings.component.html',
  styleUrl: './ratings.component.scss',
})
export class RatingsComponent implements OnInit {
  userId = null;
  reviews = signal<StationReview[]>(undefined);
  nextPage = undefined;
  hasMore = true;
  selector = '.mat-sidenav-content';

  constructor(
    private profileService: ProfileService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.parent.paramMap.subscribe((params) => {
      this.userId = params.get('userId');

      this.loadData();
    });
  }

  loadData() {
    this.profileService
      .getReviewsByUserId({ userId: this.userId, page: this.nextPage })
      .subscribe((data) => {
        this.reviews.update((prev) => [...(prev ?? []), ...(data?.data ?? [])]);
        this.nextPage =
          data?.totalPages >= data?.currentPage + 1
            ? data?.currentPage + 1
            : undefined;
        this.hasMore = !!this.nextPage;
      });
  }

  onScroll() {
    if (this.hasMore) {
      this.loadData();
    }
  }

  onReviewDelete(commentId: string) {
    this.reviews.update((prev) =>
      prev.filter((comment) => comment?.commentId !== commentId),
    );
  }
}
