<div class="app-amenities-list">
  <button
    class="amenity-item {{ selected ? 'selected' : '' }} {{
      selectable ? 'selectable' : ''
    }}"
    *ngFor="let amenity of amenities"
  >
    <div class="icon">
      <img [src]="generateIcon(amenity.name)" />
    </div>

    <p class="name">
      {{ amenity.name }}
    </p>
  </button>
</div>
