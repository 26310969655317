import { Charger } from '@app/services/station.interface';
import { VehicleInterface } from '@app/services/vehicle.interface';

export const getPlugs = (chargers: Charger[]) => {
  return {
    available: (chargers ?? []).reduce(
      (acc, charger) =>
        acc +
        (charger.connectors?.filter(
          (connector) => connector.status === 'AVAILABLE',
        ).length ?? 0),
      0,
    ),
    total: (chargers ?? []).reduce(
      (acc, charger) => acc + (charger.connectors?.length ?? 0),
      0,
    ),
  };
};

export const getSpeed = (kwh: number) => {
  if (kwh === 0) {
    return 'DefaultMarker';
  } else if (kwh < 3) {
    return 'Slow';
  } else if (kwh > 2 && kwh < 24) {
    return 'Accelerated';
  } else {
    return 'Rapid';
  }
};

export const getMarkerUrl = (chargers: Charger[]) => {
  const totalChargeKwh = chargers.reduce((sum, item) => {
    return sum + item.connectors.reduce((connectorSum, connector) => {
      return connectorSum + connector.chargeKwh;
    }, 0);
  }, 0);

  // Check if the charger is available
  const available = chargers.reduce((acc, charger) => {
    return acc + charger.connectors.reduce((acc, connector) => {
      return acc + (connector.status === "AVAILABLE" ? 1 : 0);
    }, 0);
  }, 0);

  const speed = getSpeed(totalChargeKwh);

  if (available) return "assets/map/icons/Available" + speed + ".svg";
  return "assets/map/icons/DefaultMarker.svg";
};

export const getDefaultVehicle = (vehicles: VehicleInterface[]) => {
  if(vehicles == null) {
    return null;
  }
  return vehicles.find((vehicle) => vehicle.default);
};
