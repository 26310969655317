import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, signal } from '@angular/core';
import {
  Charger,
  ConnectorChargingList,
  ConnectorWaitingList,
} from '@app/services/station.interface';
import { ConnectorData } from '../connectors-tab.component';
import { StationService } from '@app/services/station.service';
import { UserService } from '@app/services/user.service';
import { MatIconModule } from '@angular/material/icon';
import { GcImageComponent } from '@app/components/gc-image/gc-image.component';
import { PublicMetadataInterface } from '@app/services/user.interface';
import { MatButtonModule } from '@angular/material/button';
import { getDefaultVehicle } from '@app/shared/utils/station';

@Component({
  selector: 'app-connector-item',
  standalone: true,
  imports: [CommonModule, MatIconModule, GcImageComponent, MatButtonModule],
  templateUrl: './connector-item.component.html',
  styleUrl: './connector-item.component.scss',
})
export class ConnectorItemComponent implements OnInit {
  @Input() connector: ConnectorData;
  @Input() charger: Charger;
  waitingListOpen = signal(false);
  chargingListOpen = signal(false);
  getDefaultVehicle = getDefaultVehicle;

  // Convert activityList to a signal
  activityList = signal<{
    waiting: (ConnectorWaitingList & { user?: PublicMetadataInterface })[];
    charging: (ConnectorChargingList & { user?: PublicMetadataInterface })[];
  }>({
    waiting: [],
    charging: [],
  });

  getAuthorPhoto = null;
  user = null;

  constructor(private stationService: StationService, private userService: UserService) {}

  ngOnInit(): void {
    this.getAuthorPhoto = this.userService.getUserPhoto;

    this.stationService.getConnectorChargingList(this.connector.id).subscribe((data) => {
      data.forEach((activity) => {
        this.userService.getMetadataById(activity.userId).subscribe((user) => {
          this.activityList.update((current) => ({
            ...current,
            charging: [
              ...current.charging,
              { ...activity, user },
            ],
          }));
        });
      });
    });

    this.stationService.getConnectorWaitingList(this.connector.id).subscribe((data) => {
      data.forEach((activity) => {
        this.userService.getMetadataById(activity.userId).subscribe((user) => {
          this.activityList.update((current) => ({
            ...current,
            waiting: [
              ...current.waiting,
              { ...activity, user },
            ],
          }));
        });
      });
    });
  }

  toggleWaitingList() {
    this.waitingListOpen.set(!this.waitingListOpen());
  }

  toggleChargingList() {
    this.chargingListOpen.set(!this.chargingListOpen());
  }
}
