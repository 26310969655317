<div class="app-reviews-tab">
  <div
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [infiniteScrollContainer]="selector"
    [fromRoot]="true"
    (scrolled)="onScroll()"
  ></div>

  <div class="reviews-bar">
    <h3>Reviews: <span id="total-review">{{ reviewsBar().totalItems }}</span></h3>
    <div class="progress">
      <div>
        <p>Excellent</p>
        <mat-progress-bar
          mode="determinate"
          [value]="reviewsBar().excellent"
          style="--mdc-linear-progress-active-indicator-color: #3ab481"
        ></mat-progress-bar>
      </div>
      <div>
        <p>Very Good</p>
        <mat-progress-bar
          mode="determinate"
          [value]="reviewsBar().veryGood"
          style="--mdc-linear-progress-active-indicator-color: #3bb880"
        ></mat-progress-bar>
      </div>
      <div>
        <p>Average</p>
        <mat-progress-bar
          mode="determinate"
          [value]="reviewsBar().average"
          style="--mdc-linear-progress-active-indicator-color: #6be7b3"
        ></mat-progress-bar>
      </div>
      <div>
        <p>Poor</p>
        <mat-progress-bar
          mode="determinate"
          [value]="reviewsBar().poor"
          style="--mdc-linear-progress-active-indicator-color: #f59f0b"
        ></mat-progress-bar>
      </div>
      <div>
        <p>Bad</p>
        <mat-progress-bar
          mode="determinate"
          [value]="reviewsBar().bad"
          style="--mdc-linear-progress-active-indicator-color: #ed4444"
        ></mat-progress-bar>
      </div>
    </div>
  </div>

  <div class="filters">
    <button
      mat-flat-button
      class="filter-button"
      (click)="openFiltersBottomSheet()"
    >
      <mat-icon>filter_list</mat-icon> Filters
    </button>
  </div>

  <div class="no-results" *ngIf="reviews().length === 0" style="text-align: center">
    No ratings given yet
  </div>

  <div class="reviews">
    <app-review-item
      *ngFor="let review of reviews()"
      [review]="review"
      (reviewDeleted)="onReviewDelete($event)"
    ></app-review-item>
  </div>
</div>
