import { StationHours as OriginalStationHours } from "@app/services/station.interface";
import { daysOfWeek } from "./consts";
import { formatStationTime, getCurrentDateTime } from "./index";

export enum StationOpenState {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
  OPENING_SOON = "OPENING_SOON",
  CLOSING_SOON = "CLOSING_SOON",
}

export interface IHours {
  day: number;
  open?: string;
  close?: string;
  open24Hours?: boolean;
};

type StationHours = IHours[];

export const isStationOpen = (
  stationHours: StationHours,
  stationTz?: string,
): StationOpenState => {
  const now = getCurrentDateTime(stationTz);
  const currentDayHours = stationHours.filter(
    (hours) => hours.day === now.getDay(),
  );

  if (currentDayHours.length === 0) {
    return StationOpenState.CLOSE; // Default to closed if no hours for the day
  }

  for (const hours of currentDayHours) {
    if (hours.open24Hours) {
      return StationOpenState.OPEN; // Always open if 24 hours
    }

    const openTime = hours.open ? formatStationTime(hours.open) : null;
    const closeTime = hours.close ? formatStationTime(hours.close) : null;

    if (!openTime || !closeTime) {
      continue; // Skip to next set of hours if no open/close time
    }

    const openingSoon = new Date(openTime.getTime() - 30 * 60000);
    const closingSoon = new Date(closeTime.getTime() - 30 * 60000);

    if (now >= openingSoon && now < openTime) {
      return StationOpenState.OPENING_SOON;
    }

    if (now >= closingSoon && now < closeTime) {
      return StationOpenState.CLOSING_SOON;
    }

    if (now >= openTime && now <= closeTime) {
      return StationOpenState.OPEN;
    }
  }

  return StationOpenState.CLOSE; // If no matching hours found, return CLOSE
};

export const nextChangeAt = (
  stationHours: StationHours,
  stationTz?: string,
): IHours => {
  const now = getCurrentDateTime(stationTz);
  const currentDayIndex = now.getDay(); // Ensure index is 0-based
  const currentDayHours = stationHours.filter(
    (hours) => hours.day === currentDayIndex,
  );

  for (const hours of currentDayHours) {
    if (hours.open24Hours) {
      return { day: currentDayIndex }; // No next change for 24 hours
    }

    const openTime = hours.open ? formatStationTime(hours.open) : null;
    const closeTime = hours.close ? formatStationTime(hours.close) : null;

    if (openTime && closeTime) {
      if (now >= openTime && now >= closeTime) {
        continue;
      }

      return { ...hours, day: currentDayIndex, open: openTime.toTimeString().slice(0, 8), close: closeTime.toTimeString().slice(0, 8) };
    }
  }

  // Check the next day's shifts if current day's shifts are over
  let nextDayIndex = (currentDayIndex + 1) % 7;
  while (nextDayIndex !== currentDayIndex) {
    const nextDayHours = stationHours.filter(
      (hours) => hours.day === nextDayIndex,
    );

    if (nextDayHours.length > 0) {
      const nextDayOpenTime = nextDayHours[0].open ? formatStationTime(nextDayHours[0].open) : null;

      if (nextDayOpenTime) {
        const nextDayOpen = new Date(now);
        nextDayOpen.setDate(nextDayOpen.getDate() + 1);
        nextDayOpen.setHours(nextDayOpenTime.getHours(), nextDayOpenTime.getMinutes(), nextDayOpenTime.getSeconds());

        return { ...nextDayHours[0], day: nextDayIndex, open: nextDayOpen.toTimeString().slice(0, 8) };
      }
    }
    nextDayIndex = (nextDayIndex + 1) % 7; // Move to the next day, wrapping around at 6
  }

  // Default case if no open/close times are set
  return { day: currentDayIndex };
};

// Convert the hours object to IHours[] format
export const convertStationHoursToIHours = (stationHours: OriginalStationHours): IHours[] => {
  const iHours: IHours[] = [];

  daysOfWeek.forEach((day, index) => {
    const dayData = stationHours?.dayHours?.[day];

    if (dayData && dayData.length > 0) {
      dayData.forEach(hour => {
        iHours.push({
          day: index,
          open: hour.open.length === 5 ? hour.open + ':00' : hour.open,
          close: hour.close.length === 5 ? hour.close + ':00' : hour.close,
          open24Hours: stationHours.open24Hours,
        });
      });
    }
  });

  return iHours;
}
