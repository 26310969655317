<div class="app-user-dropdown">
  @if (user() === undefined) {
  } @else if (!user() || user()?.permission !== 'logged-in') {
    <button (click)="openLoginDialog()" mat-raised-button>
      Login
    </button>
  } @else {
    <!-- User Profile Button -->
    <button
      class="user-dropdown-button"
      mat-icon-button
      [matMenuTriggerFor]="userMenu"
    >
      <div class="avatar" [class]="user()?.permission === 'logged-in' ? '' : 'warning'">
        <mat-icon>person</mat-icon>
        <app-gc-image [src]="getUserPhoto?.(user().uid)" alt="" />
      </div>
    </button>

    <!-- Dropdown Menu -->
    <mat-menu #userMenu="matMenu" class="user-dropdown-menu">
      <!-- User Info -->
      <a [routerLink]="user()?.permission === 'logged-in' ? ['/profile', user().uid] : undefined" mat-menu-item>
        <div class="user-info">
          <div class="avatar" [class]="user()?.permission === 'logged-in' ? '' : 'warning'">
            <mat-icon>person</mat-icon>
            <app-gc-image [src]="getUserPhoto?.(user().uid)" alt="" />
          </div>
          <div class="author">
            <p class="user-name">{{ user().displayName }}</p>
            <a *ngIf="user()?.permission === 'logged-in'" [routerLink]="['/profile', user().uid]">View profile</a>
          </div>
        </div>
      </a>
      <mat-divider></mat-divider>
      @if (user()?.permission === 'logged-in') {
        <a routerLink="/account" mat-menu-item>
          <mat-icon>person</mat-icon>
          <span>My account</span>
        </a>
        <button mat-menu-item [routerLink]="['/profile', user().uid]">
          <mat-icon>emoji_events</mat-icon>
          <span>Rewards</span>
        </button>
      } @else {
        <button mat-menu-item class="verify-button" (click)="openVerifyDialog()">
          <mat-icon>warning</mat-icon>
          <span>Verify</span>
        </button>
      }
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Sign out</span>
      </button>
    </mat-menu>
  }
</div>
