<div class="app-station-image">
  <mat-toolbar>
    <button mat-icon-button aria-label="Back button" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <button mat-icon-button aria-label="More options" [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="openReportDialog()">
        <mat-icon>report</mat-icon>
        Report
      </button>
      @if (stationImage.uploadedBy === userSignal()?.uid) {
        <button mat-menu-item (click)="delete()">
          <mat-icon>delete</mat-icon>
          Delete
        </button>
      }
    </mat-menu>
  </mat-toolbar>

  @if (stationImage) {
    <div class="station-content">
      <div class="station-image">
        <app-gc-image
          [src]="stationImage.imageUrl"
          [noHeader]="true"
        ></app-gc-image>
      </div>

      @if (userData && !isUserdataLoading()) {
        <div class="station-author">
          <a [routerLink]="['/profile', stationImage.uploadedBy]" class="author">
            <div class="avatar">
              <mat-icon>person</mat-icon>
              <app-gc-image
                [src]="getUserPhoto(stationImage.uploadedBy)"
                alt=""
              />
            </div>
            <div class="content">
              <span class="name">{{ userData.displayName }}</span>
              <span class="vehicle">
                {{ defaultVehicle?.make }}
                {{ defaultVehicle?.model }}
              </span>
            </div>
          </a>
          <div class="date">
            {{ uploadedDate }}
          </div>
        </div>
      } @else if (isUserdataLoading()) {
        <div class="station-author">
          <div class="author">
            <div class="avatar"></div>
            <div class="content">
              <app-skeleton style="width: 165px; height: 22px"></app-skeleton>
              <app-skeleton style="width: 224px; height: 18px"></app-skeleton>
            </div>
          </div>
          <div class="date">
            <app-skeleton style="width: 105px; height: 16px"></app-skeleton>
          </div>
        </div>
      }
    </div>
  } @else {
    <div class="station-content">Image not found.</div>
  }
</div>
