import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StationsListComponent } from '@app/components/profile/stations-list/stations-list.component';

@Component({
  selector: 'app-edited-stations',
  standalone: true,
  imports: [StationsListComponent],
  templateUrl: './edited-stations.component.html',
  styleUrl: './edited-stations.component.scss'
})
export class EditedStationsComponent implements OnInit {
  userId = null;

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.parent.params.subscribe((params) => {
      this.userId = params['userId'];
    });
  }
}
