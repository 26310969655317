<div class="app-review-item">
  <div class="header">
    <a [routerLink]="['/profile', review.userId]" >
      <div class="avatar">
        <mat-icon>person</mat-icon>
        <app-gc-image [src]="getAuthorPhoto?.(review.userId)" alt="" />
      </div>
      <div class="author">
        <span class="name">{{ review.name }}</span>
        <span class="vehicle">
          {{ review.currentVehicleMake }} {{ review.currentVehicleModel }}
        </span>
      </div>
    </a>
    <div class="delete-button">
      <button mat-icon-button aria-label="More options" [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openReportDialog()">
          <mat-icon>report</mat-icon>
          Report
        </button>
        <button *ngIf="review.userId === userSignal()?.uid" mat-menu-item (click)="delete()">
          <mat-icon>delete</mat-icon>
          Delete
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="rating-icons">
    @for (i of [1, 2, 3, 4, 5]; track i) {
      <mat-icon [class]="i <= review.ratingScore ? 'active' : ''">
        star
      </mat-icon>
    }
  </div>

  <h4 class="title">{{ review.title }}</h4>
  <p class="description">{{ reviewDescription() }}</p>

  @if (review.description?.length > defaultLength) {
    <button (click)="toggleFullText()" class="toggle-button">
      {{ showFullText() ? "Show Less" : "Show More" }}
    </button>
  }

  <p class="people-found-helpful">
    {{ review.totalLikes }} people found this helpful
  </p>

  <button *ngIf="authService.isAuthenticated() && authService.userSignal().uid !== review.userId" class="helpful-button {{ isLiked() ? 'liked' : '' }}" (click)="toggleHelpful()">
    <mat-icon>thumb_up</mat-icon>
    Helpful
  </button>

  <p class="date">{{ formatDate(review.createdDate) }}</p>
</div>
