import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contribution, StationWithImages } from './profile.interface';
import { environment } from '@environments/environment';
import { StationReview } from './station.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  getRewards(userId: string) {
    return this.http.get<{
      contributions: Contribution[];
      contributionCount: number;
    }>(`${environment.api.rewards}/contributions?userId=${userId}`);
  }

  getStationImagesByUserId(userId: string) {
    return this.http.get<StationWithImages[]>(
      `${environment.api.file}/files/station/v2/user-station-image/${userId}`,
    );
  }

  getReviewsByUserId({
    userId,
    size = 10,
    page = 0,
  }: {
    userId: string;
    size?: number;
    page?: number;
  }) {
    return this.http.get<{
      data: StationReview[];
      currentPage: number;
      numberOfItemsInCurrentPage: number;
      totalItems: number;
      totalPages: number;
    }>(
      `${environment.api.feedback}/comment/user-comment?userId=${userId}&size=${size}&page=${page}`,
    );
  }
}
