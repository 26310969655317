<div class="app-profile-layout dash-container" *ngIf="user">
  <div class="user-card">
    <div class="user-photo">
      <mat-icon>person</mat-icon>
      <app-gc-image [src]="userPhoto" />
    </div>
    <h3 class="user-name">
      {{ user?.displayName }}
    </h3>
    <p>{{ defaultVehicle?.make }} {{ defaultVehicle?.model }}</p>
    <div class="user-actions">
      <button mat-button color="primary" (click)="openReportDialog()">
        <mat-icon>report</mat-icon>
        Report
      </button>
    </div>
  </div>
  <div class="profile-content">
    <nav class="profile-nav">
      <a
        routerLink="./"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Rewards
      </a>
      <a routerLink="added-stations" routerLinkActive="active">Added stations</a>
      <a routerLink="edited-stations" routerLinkActive="active">
        Edited stations
      </a>
      <a routerLink="images" routerLinkActive="active">Images</a>
      <a routerLink="ratings" routerLinkActive="active">Ratings</a>
    </nav>

    <router-outlet></router-outlet>
  </div>
</div>

<div
  class="dash-container"
  *ngIf="user === undefined"
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  "
>
  <mat-spinner [diameter]="40"></mat-spinner>
</div>
