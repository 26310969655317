export const REPORT_TYPES = {
  SPAM: 'Spam',
  NUDE: 'Nudity',
  HATE_SPEECH: 'Hate Speech',
  HARASSMENT: 'Harassment',
  FALSE_INFORMATION: 'False Information',
  VIOLENCE: 'Violence',
  SCAM_FRAUD: 'Scam/Fraud',
  OTHER: 'Other',
};
