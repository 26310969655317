<div>
  <a routerLink="/station/{{ station.id }}" class="app-station-list-item">
    <div class="image">
      <mat-icon>ev_station</mat-icon>
      <app-gc-image [src]="getStationImageUrl(station.id)" alt="" />
    </div>
    <div class="details">
      <p class="name" *ngIf="station.name">
        {{ station.name }}
      </p>
      <p class="address">
        {{ fullAddress }}
      </p>

      @if (images?.length > 0) {
        <div class="station-images">
          <a
            [routerLink]="getLink(image)"
            class="masonry-item"
            *ngFor="let image of images"
          >
            <app-skeleton
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
              "
            ></app-skeleton>
            <img [src]="image.imageUrl" />
          </a>
        </div>
      }

      <p class="created-at">Added on {{ createdDate }}</p>
    </div>
  </a>
</div>
<mat-divider></mat-divider>
