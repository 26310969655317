import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, signal } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { Station } from '@app/services/station.interface';
import { daysOfWeek, WEEKDAYS } from '@app/shared/utils/consts';
import { formatTime } from '@app/shared/utils';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { ConnectorListComponent } from '../connector-list/connector-list.component';
import { AmenitiesListComponent } from '../amenities-list/amenities-list.component';
import dayjs from 'dayjs';
import { convertStationHoursToIHours, isStationOpen, nextChangeAt, StationOpenState } from '@app/shared/utils/station-hour';
import { convertStationPricingHoursToIPricingHours, nextPricingChangeAt } from '@app/shared/utils/station-cost';

@Component({
  selector: 'app-station-info',
  standalone: true,
  imports: [
    LeafletModule,
    MatDividerModule,
    MatIconModule,
    CommonModule,
    ConnectorListComponent,
    AmenitiesListComponent,
  ],
  templateUrl: './station-info.component.html',
  styleUrl: './station-info.component.scss',
})
export class StationInfoComponent implements OnInit {
  @Input() station: Station = null;

  readonly hoursOpenState = signal(false);
  readonly costsOpenState = signal(false);
  openHoursText = signal('');
  costsText = signal('');

  formatTime = formatTime;
  weekdays = WEEKDAYS;
  showAllAmenities = false;
  lastModifiedDate = '';
  connectorStatus = '';
  stationHours = this.station?.hours?.dayHours;

  ngOnInit(): void {
    this.lastModifiedDate = dayjs(this.station?.updatedTs).format('MM.DD.YYYY');
    this.getOpenHoursText();
    this.getCostsText();

    const isAvailable = this.station?.chargers.some(charger =>
      charger.connectors.some(connector => connector.status === "AVAILABLE")
    );
    this.connectorStatus = isAvailable ? "Available" : "Unknown";

    const filteredHours = {};
    Object.keys(this.station?.hours?.dayHours).forEach(day => {
      const filtered = this.station?.hours?.dayHours[day].filter(period => period?.open && period?.close);
      if (filtered.length > 0) {
        filteredHours[day] = filtered;
      }
    });
    this.stationHours = filteredHours;
  }

  get displayedAmenities() {
    return this.showAllAmenities
      ? this?.station?.amenities
      : this?.station?.amenities?.slice(0, 6);
  }

  toggleAmenities() {
    this.showAllAmenities = !this.showAllAmenities;
  }

  convertLowerCase(string: string) {
    return String(string).toLowerCase();
  }

  getOpenHoursText() {
    if (this.station?.hours?.open24Hours) {
      this.openHoursText.set(`<span class="text-green">Open 24 hours</span>`);
      return;
    }

    const convertedStationHours = convertStationHoursToIHours(this.station.hours);
    const status = isStationOpen(convertedStationHours);
    const nextData = nextChangeAt(convertedStationHours);

    if (status === StationOpenState.OPEN) {
      this.openHoursText.set(`<span class="text-green">Open</span> <span>| Closes ${this.formatDateTime(nextData.day, nextData.close)}</span>`);
    } else if (status === StationOpenState.CLOSE) {
      this.openHoursText.set(`<span class="text-red">Closed</span> <span>| Opens ${this.formatDateTime(nextData.day, nextData.open)}</span>`);
    } else if (status === StationOpenState.OPENING_SOON) {
      this.openHoursText.set(`<span class="text-red">Closed</span> <span>| Opening soon ${formatTime(nextData.open)}</span>`);
    } else if (status === StationOpenState.CLOSING_SOON) {
      this.openHoursText.set(`<span class="text-orange">Open</span> <span>| Closing soon ${formatTime(nextData.close)}</span>`);
    }
  }

  getCostsText() {
    const convertedStationHours = convertStationPricingHoursToIPricingHours(this.station.stationPricingHours);
    const nextData = nextPricingChangeAt(convertedStationHours);

    this.costsText.set(`
      <span class="${nextData.isPaid ? "text-red" : "text-green"}">${nextData.isPaid ? "Paid" : "Free"}</span>
      <span>|</span>
      <span>${WEEKDAYS[daysOfWeek[nextData.day]]}</span>
      <span>${this.formatTime(nextData.from)} - ${this.formatTime(nextData.to)}</span>
    `);
  }

  formatDateTime(dayIndex: number, time: string) {
    return `${WEEKDAYS[daysOfWeek[dayIndex]]} ${formatTime(time)}`;
  }

  hasDayHoursEntries() {
    return !!this.stationHours && Object.values(this.stationHours).some(day => day && day.length > 0);
  }

  hasCostsEntries() {
    return !!this.station.stationPricingHours.pricingHours && Object.values(this.station.stationPricingHours.pricingHours).some(day => day && day.length > 0);
  }
}
