import {Injectable} from '@angular/core';
import {latLng, MapOptions, tileLayer} from "leaflet";

@Injectable({
  providedIn: 'root'
})
export class LeafletConfigService {

  constructor() {
  }

  public defaultMapOptions: MapOptions = {
    attributionControl: false,
    layers: [tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
    })
    ],
    zoom: 5,
    minZoom: 3,
    maxZoom: 18,
    zoomControl: false,
    zoomSnap: 0.5,
    center: latLng(37.0902, -95.7129)
  }
}
