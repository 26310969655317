import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    MatSidenavModule,
    MatToolbarModule,
    SidebarComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  constructor(iconRegistry: MatIconRegistry, private authService: AuthService) {
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }
  title = 'gitcharger-web';

  ngOnInit(): void {
    this.authService.getUserObject().subscribe((user) => {
      this.authService.userSignal.set(user ?? null);
    });
  }
}
