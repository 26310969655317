import { Component, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UserService } from '@app/services/user.service';
import { AuthService } from '@app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseError } from 'firebase/app';
import { catchError, finalize, firstValueFrom, of } from 'rxjs';
import { User } from 'firebase/auth';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent {
  private user: User | null = null;
  isUpdating = signal<boolean>(false);
  status = signal<'initial' | 'verify' | 'reset'>('initial');
  private _formBuilder = inject(FormBuilder);
  initialFormGroup = this._formBuilder.group({
    currentPassword: ['', Validators.required],
  });
  verifyFormGroup = this._formBuilder.group({
    code: ['', Validators.required],
  });
  resetFormGroup = this._formBuilder.group({
    newPassword: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
  });

  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
    this.authService.user$.subscribe((user) => {
      this.user = user;
    });
  }

  async onInitialSubmit() {
    if (!this.initialFormGroup.valid) return;

    this.isUpdating.set(true);

    const formData = this.initialFormGroup.value;
    const email = this.user?.email;
    const password = formData.currentPassword;

    try {
      await firstValueFrom(this.authService.login(email, password));
      await firstValueFrom(this.authService.resendVerificationCode(email));

      this.snackBar.open('Verification code sent to your email', undefined, {
        duration: 3000,
      });
      this.status.set('verify');
    } catch (error) {
      if (error instanceof FirebaseError) {
        this.snackBar.open(
          error.code === 'auth/user-not-found' ||
            error.code === 'auth/wrong-password'
            ? 'Incorrect current password'
            : 'Something went wrong. Please try again later',
          undefined,
          {
            duration: 3000,
          },
        );
      } else {
        this.snackBar.open('Something went wrong', undefined, {
          duration: 3000,
        });
        this.initialFormGroup.reset();
      }
    } finally {
      this.isUpdating.set(false);
    }
  }

  async onVerifySubmit() {
    if (!this.verifyFormGroup.valid) return;
    const formData = this.verifyFormGroup.value;

    console.log('hey');
    this.isUpdating.set(true);

    try {
      await firstValueFrom(
        this.authService.verifyCode(this.user?.email, formData.code),
      );
      this.status.set('reset');
      this.isUpdating.set(false);
      this.verifyFormGroup.reset();
    } catch {
      this.snackBar.open('Incorrect verification code', undefined, {
        duration: 3000,
      });
      this.isUpdating.set(false);
    }
  }

  async onResetSubmit() {
    if (!this.resetFormGroup.valid) return;
    const { uid, email } = this.user;
    const formData = this.resetFormGroup.value;

    if (formData.newPassword !== formData.confirmPassword) {
      this.snackBar.open('Passwords do not match', undefined, {
        duration: 3000,
      });
      return;
    }

    this.isUpdating.set(true);

    const data: { status?: string } = await firstValueFrom(
      this.authService.updatePassword(uid, formData.newPassword),
    );
    if (data?.status !== 'OK') {
      this.snackBar.open('Something went wrong', undefined, {
        duration: 3000,
      });
      return;
    }

    // Login with the new password, as the token is invalidated due to the password change
    await firstValueFrom(this.authService.login(email, formData.newPassword));

    this.snackBar.open('Password updated', undefined, {
      duration: 3000,
    });

    this.isUpdating.set(false);
    this.status.set('initial');
    this.initialFormGroup.reset();
    this.verifyFormGroup.reset();
    this.resetFormGroup.reset();
  }
}
