import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Station, StationReview } from '@app/services/station.interface';
import { StationService } from '@app/services/station.service';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ReviewItemComponent } from '../review-item/review-item.component';
import { MatButtonModule } from '@angular/material/button';
import { FiltersBottomSheetComponent } from './filters-bottom-sheet/filters-bottom-sheet.component';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-ratings-tab',
  standalone: true,
  imports: [
    InfiniteScrollDirective,
    CommonModule,
    MatIcon,
    ReviewItemComponent,
    MatButtonModule,
    MatProgressBarModule,
    MatCardModule,
  ],
  templateUrl: './ratings-tab.component.html',
  styleUrl: './ratings-tab.component.scss',
})
export class RatingsTabComponent implements OnInit {
  @Input() station: Station = null;
  private _filtersDialog = inject(MatDialog);
  initialReviews = signal<StationReview[]>([]);
  reviews = signal<StationReview[]>([]);
  nextPage = undefined;
  hasMore = true;
  selector = '.simplebar-content-wrapper';
  reviewsBar = signal({
    totalItems: 0,
    excellent: 0,
    veryGood: 0,
    average: 0,
    poor: 0,
    bad: 0,
  });

  constructor(
    private stationService: StationService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.stationService
      .getStationReviewsById(this?.station?.id, this.nextPage)
      .subscribe((data) => {
        this.initialReviews.update((prev) => [...prev, ...(data?.data ?? [])]);
        this.nextPage =
          data?.totalPages >= data?.currentPage + 1
            ? data?.currentPage + 1
            : undefined;
        this.hasMore = !!this.nextPage;

        this.reviewsBar.update((prev) => ({
          ...prev,
          totalItems: data?.totalItems ?? 0,
        }));

        // Filter
        this.route.queryParams.subscribe((params) => {
          this.handleFilters(params);
        });
      });

    this.updateReviewsBar();
  }

  updateReviewsBar() {
    this.stationService
      .getStationRatings(this?.station?.id)
      .subscribe((ratings) => {
        const total =
          ratings.five +
          ratings.four +
          ratings.three +
          ratings.two +
          ratings.one;

        this.reviewsBar.update((prev) => ({
          ...prev,
          excellent: (ratings.five / total) * 100,
          veryGood: (ratings.four / total) * 100,
          average: (ratings.three / total) * 100,
          poor: (ratings.two / total) * 100,
          bad: (ratings.one / total) * 100,
        }));
      });
  }

  onScroll() {
    if (this.hasMore) {
      this.loadData();
    }
  }

  openFiltersBottomSheet() {
    this._filtersDialog.open(FiltersBottomSheetComponent);
  }

  handleFilters(params) {
    const rateFilterData = {
      commentType: params['comment_type']?.split(',') ?? [],
      rating: params['rating']?.split(',') ?? [],
    };

    const reviews = this.initialReviews()?.filter((comment) => {
      if (rateFilterData.commentType.length > 0) {
        return rateFilterData.commentType.includes(comment?.title);
      }
      if (rateFilterData.rating.length > 0) {
        return rateFilterData.rating.includes(String(comment?.ratingScore));
      }
      return true;
    });

    this.reviews.set(reviews);
  }

  onReviewDelete(commentId: string) {
    this.reviews.update((prev) =>
      prev.filter((comment) => comment?.commentId !== commentId),
    );
    this.reviewsBar.update((prev) => ({
      ...prev,
      totalItems: prev.totalItems - 1,
    }));
    this.updateReviewsBar();
  }
}
