import { Component } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [RouterOutlet, MatListModule, RouterLink, RouterLinkActive],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
})
export class AccountComponent {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.authService.whenNotAuthenticated(() => {
      this.router.navigate(['/']);
    });
  }
}
