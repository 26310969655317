import {
  Component,
  Input,
  OnChanges,
  signal,
  WritableSignal,
} from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { StationInfoComponent } from '../../components/station-details/station-info/station-info.component';
import { StationService } from '@app/services/station.service';
import { Station } from '@app/services/station.interface';
import { prettyAddress } from '@app/shared/utils';
import { MatDividerModule } from '@angular/material/divider';
import { ImageService } from '@app/shared/services/image.service';
import { SkeletonComponent } from '@app/components/skeleton/skeleton.component';
import { ConnectorsTabComponent } from '@app/components/station-details/connectors-tab/connectors-tab.component';
import { ImagesTabComponent } from '@app/components/station-details/images-tab/images-tab.component';
import { RatingsTabComponent } from '@app/components/station-details/ratings-tab/ratings-tab.component';
import { Router, RouterOutlet } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GcImageComponent } from '@app/components/gc-image/gc-image.component';
import { firstValueFrom } from 'rxjs';
import { SimplebarAngularModule } from 'simplebar-angular';
import { AuthService, UserSignal } from '@app/services/auth.service';
import { getMarkerUrl } from '@app/shared/utils/station';
import { ReportDialogComponent } from '@app/components/report-dialog/report-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CategoryType } from '@app/services/feedback.interface';

@Component({
  selector: 'app-station-details',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    NgOptimizedImage,
    StationInfoComponent,
    MatDividerModule,
    SkeletonComponent,
    ConnectorsTabComponent,
    ImagesTabComponent,
    RatingsTabComponent,
    RouterOutlet,
    GcImageComponent,
    SimplebarAngularModule,
  ],
  templateUrl: './station-details.component.html',
  styleUrl: './station-details.component.scss',
})
export class StationDetailsComponent implements OnChanges {
  @Input() stationId = '';
  station: Station = null;
  loadingStates: WritableSignal<{
    station?: boolean;
    image?: boolean;
    ratings?: boolean;
  }> = signal({
    station: false,
    image: false,
    ratings: false,
  });
  fullAddress = '';
  stationImage = null;
  ratings: { rating: number; count: number } = null;
  isBookmarked = signal(false);
  user = signal<UserSignal>(null);
  markerUrl = '';

  constructor(
    private snackBar: MatSnackBar,
    private stationService: StationService,
    private imageService: ImageService,
    public authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.user = this.authService.userSignal;
  }

  async ngOnChanges(): Promise<void> {
    this.station = null;
    this.stationImage = null;
    this.fullAddress = '';
    this.ratings = null;

    this.loadingStates.update((prev) => ({
      ...prev,
      station: true,
    }));
    this.station = await firstValueFrom(
      this.stationService.getStationById([this.stationId]),
    ).then((station) => station?.data?.stationById[0] ?? null);
    this.loadingStates.update((prev) => ({
      ...prev,
      station: false,
    }));

    if (!this.station) {
      this.snackBar.open('Station not found', undefined, {
        duration: 3000,
      });
      return;
    }

    this.markerUrl = getMarkerUrl(this.station.chargers);
    let cityAndState = this.station?.locationCity && this.station?.locationState ? this.station?.locationCity + ', ' + this.station?.locationState : (this.station?.locationCity ?? '') + (this.station?.locationState ?? '');
    cityAndState = cityAndState ? cityAndState + ' |' : '';
    document.title = `${this.station.name} | ${cityAndState} EV Station`;

    this.fullAddress = prettyAddress(
      this.station?.locationAddress,
      this.station?.locationAddress2,
      this.station?.locationCity,
      this.station?.locationState,
    );
    this.isBookmarked.set(this.station?.isBookmarked ?? false);

    this.loadingStates.update((prev) => ({
      ...prev,
      ratings: true,
    }));
    await this.getRatings().finally(() => {
      this.loadingStates.update((prev) => ({
        ...prev,
        ratings: false,
      }));
    });

    this.loadingStates.update((prev) => ({
      ...prev,
      image: true,
    }));
    this.imageService
      .getImage(
        this.stationService.getStationImageUrl(this.stationId)
      )
      .subscribe({
        next: (src: string) => {
          this.stationImage = src;
          this.loadingStates.update((prev) => ({
            ...prev,
            image: false,
          }));
        },
        error: () => {
          this.stationImage = null;
          this.loadingStates.update((prev) => ({
            ...prev,
            image: false,
          }));
        },
      });
  }

  async getRatings() {
    try {
      const ratingsData = await firstValueFrom(
        this.stationService.getStationRatings(this.stationId),
      );

      const sumOfRatings =
        (1 * ratingsData.one || 0) +
        (2 * ratingsData.two || 0) +
        (3 * ratingsData.three || 0) +
        (4 * ratingsData.four || 0) +
        (5 * ratingsData.five || 0);
      const numberOfRatings =
        (ratingsData.one ?? 0) +
        (ratingsData.two ?? 0) +
        (ratingsData.three ?? 0) +
        (ratingsData.four ?? 0) +
        (ratingsData.five ?? 0);
      const average = sumOfRatings / numberOfRatings;

      this.ratings = {
        rating: Number(average.toFixed(1)) || 0,
        count: numberOfRatings,
      };
    } catch {
      this.ratings = { rating: 0, count: 0 };
    }
  }

  share() {
    const shareLink = window.location.origin + '/station/' + this.stationId;
    navigator.clipboard.writeText(shareLink);
    this.snackBar.open('Link copied to clipboard', undefined, {
      duration: 3000,
    });
  }

  async toggleBookmark() {
    if (!this.authService.isAuthenticated()) return;

    if (this.isBookmarked()) {
      // already bookmarked
      this.isBookmarked.set(false);
      this.stationService.deleteBookmark(this.stationId).subscribe();
      this.snackBar.open('Bookmark removed', undefined, { duration: 3000 });
    } else {
      const bookmarks = await firstValueFrom(this.stationService.getBookmarks(this.user().uid));
      if (bookmarks.length >= 10) {
        this.snackBar.open('You have reached the maximum number of bookmarked stations', undefined, { duration: 3000 });
        return;
      }

      this.isBookmarked.set(true);
      this.stationService.addBookmark(this.stationId, this.user().uid).subscribe();
      this.snackBar.open('Bookmarked', undefined, { duration: 3000 });
    }
  }

  openReportDialog() {
    this.dialog.open(ReportDialogComponent, {
      width: '500px',
      data: {
        categoryType: CategoryType.STATION,
        categoryId: this.stationId,
      },
    });
  }
  
  goBack() {
    this.router.navigate(['/']);
  }
}
