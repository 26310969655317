<div class="app-change-password">
  <h1 class="account-page-title">Change password</h1>

  <form
    [formGroup]="initialFormGroup"
    (ngSubmit)="onInitialSubmit()"
    *ngIf="status() === 'initial'"
  >
    <mat-form-field appearance="outline">
      <mat-label>Current password</mat-label>
      <input
        matInput
        type="password"
        formControlName="currentPassword"
        required
      />
    </mat-form-field>
    <div>
      <button [disabled]="isUpdating()" mat-flat-button matStepperNext>
        Next
      </button>
    </div>
  </form>

  <form
    [formGroup]="verifyFormGroup"
    (ngSubmit)="onVerifySubmit()"
    *ngIf="status() === 'verify'"
  >
    <mat-form-field appearance="outline">
      <mat-label>Verification code</mat-label>
      <input matInput formControlName="code" required />
    </mat-form-field>
    <div>
      <button [disabled]="isUpdating()" mat-flat-button matStepperNext>
        Next
      </button>
    </div>
  </form>

  <form
    [formGroup]="resetFormGroup"
    (ngSubmit)="onResetSubmit()"
    *ngIf="status() === 'reset'"
  >
    <mat-form-field appearance="outline">
      <mat-label>New password</mat-label>
      <input matInput type="password" formControlName="newPassword" required />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Confirm password</mat-label>
      <input matInput type="password" formControlName="confirmPassword" required />
    </mat-form-field>
    <div>
      <button [disabled]="isUpdating()" mat-flat-button matStepperNext>
        Reset password
      </button>
    </div>
  </form>
</div>
