<div class="app-stations-list">
  <div class="stations-list" *ngIf="data()?.length > 0">
    <app-station-list-item
      [station]="station"
      *ngFor="let station of data()"
    ></app-station-list-item>
  </div>

  <div class="no-stations" *ngIf="data()?.length === 0">
    <img src="assets/link-expired.svg" />
    <p>No stations {{ contributionType === "UPDATE" ? "edited" : "added" }} yet.</p>
  </div>

  <div class="stations-loading" *ngIf="data() === undefined">
    <mat-spinner [diameter]="20"></mat-spinner> Loading...
  </div>
</div>
