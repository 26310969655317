<main class="app-contact container">
  <div class="header">
    <h1 class="title">Contact GitCharger</h1>
    <p class="description">
      Let’s get this conversation started. Email us at
      <a href="mailto:contact@gitcharger.com">contact&#64;gitcharger.com</a> and
      tell us how we can help you.
    </p>
  </div>

  <div class="ui-elements">
    <svg
      class="left"
      width="43"
      height="123"
      viewBox="0 0 43 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="-0.5" cy="61.2178" rx="43.5" ry="60.9326" fill="#99FF97" />
      <ellipse
        cx="-0.681099"
        cy="60.9611"
        rx="24.6744"
        ry="34.5626"
        fill="#FBFBFB"
      />
    </svg>

    <svg
      class="middle"
      width="204"
      height="204"
      viewBox="0 0 204 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="102" cy="102" r="102" fill="#DDFFDC" />
      <circle cx="101.572" cy="101.572" r="57.8571" fill="#FBFBFB" />
    </svg>

    <svg
      class="right"
      width="125"
      height="238"
      viewBox="0 0 125 238"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="119" cy="119" r="119" fill="#99FF97" />
      <circle cx="118.5" cy="118.5" r="67.5" fill="#EFF6FB" />
    </svg>
  </div>
</main>
