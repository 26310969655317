export const WEEKDAYS = {
  SUN: 'Sunday',
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
};

export const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export const CONNECTOR_ICONS = {
  '': 'assets/connectors/TypeA.svg',
  CcsType1: 'assets/connectors/CCSType1.svg',
  CcsType2: 'assets/connectors/CCSType2.svg',
  ChAdeMo: 'assets/connectors/CHAdeMo.svg',
  Commando: 'assets/connectors/Commando.svg',
  Indistrial3PhasePlug: 'assets/connectors/Indistrial3PhasePlug.svg',
  J1772: 'assets/connectors/J1772.svg',
  Nema1030: 'assets/connectors/NEMA1030.svg',
  Nema1430: 'assets/connectors/NEMA1430.svg',
  Nema1450: 'assets/connectors/NEMA1450.svg',
  Nema515: 'assets/connectors/NEMA515.svg',
  Nema520: 'assets/connectors/NEMA520.svg',
  Nema615: 'assets/connectors/NEMA615.svg',
  Nema620: 'assets/connectors/NEMA620.svg',
  Nema650: 'assets/connectors/NEMA650.svg',
  Tesla: 'assets/connectors/Tesla.svg',
  Type3CType3: 'assets/connectors/Type3CType3.svg',
  TypeA: 'assets/connectors/TypeA.svg',
  TypeB: 'assets/connectors/TypeB.svg',
  TypeC: 'assets/connectors/TypeC.svg',
  TypeD: 'assets/connectors/TypeD.svg',
  TypeE: 'assets/connectors/TypeE.svg',
  TypeF: 'assets/connectors/TypeF.svg',
  TypeG: 'assets/connectors/TypeG.svg',
  TypeH: 'assets/connectors/TypeH.svg',
  TypeI: 'assets/connectors/TypeI.svg',
  TypeJ: 'assets/connectors/TypeJ.svg',
  TypeK: 'assets/connectors/TypeK.svg',
  TypeL: 'assets/connectors/TypeL.svg',
};
