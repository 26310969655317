import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GcImageComponent } from '@app/components/gc-image/gc-image.component';
import { AuthService } from '@app/services/auth.service';
import { PublicMetadataInterface } from '@app/services/user.interface';
import { UserService } from '@app/services/user.service';
import { VehicleService } from '@app/services/vehicle.service';
import { MatRadioModule } from '@angular/material/radio';
import { User } from 'firebase/auth';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { AddVehicleDialogComponent } from '@app/components/account/vehicles/add-vehicle-dialog/add-vehicle-dialog.component';
import { VehicleInterface } from '@app/services/vehicle.interface';
import { DeleteVehicleDialogComponent } from '@app/components/account/vehicles/delete-vehicle-dialog/delete-vehicle-dialog.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-vehicles',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    GcImageComponent,
    MatRadioModule,
    MatButtonModule,
  ],
  templateUrl: './vehicles.component.html',
  styleUrl: './vehicles.component.scss',
})
export class VehiclesComponent implements OnInit {
  data = signal<VehicleInterface[]>(undefined);
  user: User = null;
  metadata: PublicMetadataInterface = null;
  isLoading = signal<boolean>(false);

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private vehicleService: VehicleService,
    readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.isLoading.set(true);  // Set loading state to true initially

    this.authService.user$.subscribe({
      next: (user) => {
        this.user = user;

        this.userService.getMetadataById(user.uid).subscribe({
          next: (data) => {
            this.metadata = data;
            this.data.set(data.userPreferences?.vehicles ?? []);
            this.isLoading.set(false);  // Set loading state to false after successful data retrieval
          },
          error: (err) => {
            console.error('Error fetching metadata:', err);
            this.isLoading.set(false);  // Ensure loading state is false even if an error occurs
          }
        });
      },
      error: (err) => {
        console.error('Error fetching user:', err);
        this.isLoading.set(false);  // Handle errors in user fetching as well
      }
    });
  }


  markAsDefault(vehicle: VehicleInterface) {
    if (vehicle.default) return;

    this.snackBar.open('Vehicle marked as default', undefined, {
      duration: 3000,
    });

    this.data.update((vehicles) => {
      vehicles.forEach((v) => {
        if (v.typeId === vehicle.typeId) {
          v.default = true;
        } else {
          v.default = false;
        }
      });

      return vehicles;
    });

    this.userService
      .updateMetadata(this.user.uid, {
        ...this.metadata,
        userPreferences: {
          ...this.metadata.userPreferences,
          vehicles: this.data(),
        },
      })
      .subscribe();
  }

  getVehicleLogo(vehicle: VehicleInterface) {
    return this.vehicleService.getVehicleLogoUrl(vehicle.make);
  }

  openAddVehicleDialog() {
    this.dialog.open(AddVehicleDialogComponent, {
      width: '500px',
    });
  }

  deleteVehicle(vehicle: VehicleInterface) {
    const dialogRef = this.dialog.open(DeleteVehicleDialogComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const newVehicles = this.data().filter(
          (v) => v.typeId !== vehicle.typeId,
        );
        if (vehicle.default && newVehicles.length > 0) {
          newVehicles[0].default = true;
        }
        this.data.set(newVehicles);

        await firstValueFrom(
          this.userService
            .updateMetadata(this.user.uid, {
              ...this.metadata,
              userPreferences: {
                ...this.metadata.userPreferences,
                vehicles: newVehicles,
              },
            })
        );
        this.snackBar.open('Vehicle deleted', undefined, {
          duration: 3000,
        });
      }
    });
  }
}
