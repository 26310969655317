export enum IssueType {
  NUDE = 'NUDE',
  SPAM = 'SPAM',
  HARASSMENT = 'HARASSMENT',
  FALSE_INFORMATION = 'FALSE_INFORMATION',
  HATE_SPEECH = 'HATE_SPEECH',
  VIOLENCE = 'VIOLENCE',
  SCAM_FRAUD = 'SCAM_FRAUD',
  OTHER = 'OTHER',
}

export enum CategoryType {
  USER = 'USER',
  STATION = 'STATION',
  IMAGE = 'IMAGE',
  FEEDBACK_COMMENT = 'FEEDBACK_COMMENT',
}

export interface ReportInterface {
  userId: string;
  categoryType: CategoryType;
  categoryId: string;
  issueType: IssueType;
  description?: string;
}
