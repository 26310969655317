import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, signal } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StationListItemComponent } from '@app/components/profile/station-list-item/station-list-item.component';
import { Contribution } from '@app/services/profile.interface';
import { ProfileService } from '@app/services/profile.service';
import { Station } from '@app/services/station.interface';
import { StationService } from '@app/services/station.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-stations-list',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, StationListItemComponent],
  templateUrl: './stations-list.component.html',
  styleUrl: './stations-list.component.scss'
})
export class StationsListComponent implements OnInit {
  @Input() contributionType: Contribution['contributionType'] = "CREATE";
  @Input() userId = null;
  data = signal<Station[]>(undefined);

  constructor(
    private stationService: StationService,
    private profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    if (!this.userId) return;

    this.profileService.getRewards(this.userId).subscribe(async (data) => {
      const stations = await firstValueFrom(
        this.stationService.getStationById(
          data.contributions.map((contribution) => contribution.stationId),
        ),
      );

      this.data.update((prev) => {
        return [
          ...(prev ?? []),
          ...data.contributions
            .filter(
              (contribution) => contribution.contributionType === this.contributionType,
            )
            .map((contribution) => {
              return stations?.data?.stationById?.find(
                (station) => station.id === contribution.stationId,
              );
            }),
        ];
      });
    });
  }
}
