import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthService } from '@app/services/auth.service';
import { RewardInterface } from '@app/services/rewards.interface';
import { RewardsService } from '@app/services/rewards.service';
import { REWARD_TYPES } from '@app/shared/data/profile';
import dayjs from 'dayjs';

@Component({
  selector: 'app-rewards',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './rewards.component.html',
  styleUrl: './rewards.component.scss',
})
export class RewardsComponent implements OnInit {
  rewards = signal<
    {
      rewardType: string;
      createdDate?: string;
      inactive?: boolean;
    }[]
  >(undefined);

  constructor(
    private rewardsService: RewardsService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.rewardsService
      .getRewards(this.authService.userSignal().uid)
      .subscribe((rewards) => {
        const rewardSummary = REWARD_TYPES.map((rewardType) => {
          const reward = rewards.find((r) => r.rewardType === rewardType);
          if (reward) {
            return {
              rewardType: reward.rewardType,
              createdDate: reward.createdDate,
              inactive: false,
            };
          } else {
            return {
              rewardType,
              inactive: true,
            };
          }
        }).sort((a, b) => {
          if (a.inactive && !b.inactive) return 1;
          if (!a.inactive && b.inactive) return -1;
          return 0;
        });

        this.rewards.set(rewardSummary);
      });
  }

  getRewardName(rewardType: string): string {
    const name = rewardType?.split('_')?.join(' ')?.toLowerCase();
    return name?.charAt(0).toUpperCase() + name?.slice(1);
  }

  getRewardDate(date: string): string {
    return dayjs(date).format('D MMM, YYYY');
  }
}
