import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { GcImageComponent } from '@app/components/gc-image/gc-image.component';
import { SkeletonComponent } from '@app/components/skeleton/skeleton.component';
import { Station, StationImage } from '@app/services/station.interface';
import { StationService } from '@app/services/station.service';
import { prettyAddress } from '@app/shared/utils';
import dayjs from 'dayjs';

@Component({
  selector: 'app-station-list-item',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    GcImageComponent,
    MatIconModule,
    RouterLink,
    SkeletonComponent,
  ],
  templateUrl: './station-list-item.component.html',
  styleUrl: './station-list-item.component.scss',
})
export class StationListItemComponent implements OnInit {
  @Input() station: Station = null;
  @Input() images: StationImage[] = [];
  createdDate = '';
  getStationImageUrl = null;
  fullAddress = '';

  constructor(private stationService: StationService) {
    this.getStationImageUrl = this.stationService.getStationImageUrl;
  }

  ngOnInit(): void {
    this.createdDate = dayjs(this.station.createdTs).format('DD.MM.YYYY');

    this.fullAddress = prettyAddress(
      this.station?.locationAddress,
      this.station?.locationAddress2,
      this.station?.locationCity,
      this.station?.locationState,
    );
  }

  getLink(image: StationImage) {
    return `/station/${image.stationId}/image/${encodeURIComponent(btoa(JSON.stringify(image)))}`;
  }
}
