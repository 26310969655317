import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function customPasswordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value || '';
    if (value.length === 0) return { required: true };
    if (value.length < 8) return { minlength: true };

    const hasUppercase = /[A-Z]/.test(value);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value);

    const passwordValid = hasUppercase && hasSpecialCharacter;

    return !passwordValid ? { customPassword: true } : null;
  };
}
